import React, { useState, useEffect } from "react";
import About from "./About";
import Description from "./Description";
import FormContact from "./FormContact";
import AuthIcons from "./../../hooks/AuthIcons";
import ScrollIndicator from "./../../hooks/ScrollIndicator";
import useScrollToTop from "./../../hooks/useScrollToTop";
import "./Home.css";
import HeaderSlider from "./HeaderSlider";
import { useUser } from "./../../contexts/UserContext";
import PartenairesCarousel from "./PartenairesCarousel";
import { saveVisite } from "../../services/visitesServices";

function HomePageVitrine() {
  useScrollToTop();
  const { user } = useUser();
  useEffect(() => {
    const today = new Date().toISOString().slice(0, 10); // Obtenir la date au format AAAA-MM-JJ
    const lastVisitDate = localStorage.getItem("lastVisitDate");

    if (lastVisitDate !== today) {
      // Si la dernière visite enregistrée est différente d'aujourd'hui, on enregistre une nouvelle visite
      saveVisite();
      localStorage.setItem("lastVisitDate", today);
    }
  }, []);
  return (
    <div>
      {!user && <AuthIcons />}
      <ScrollIndicator />
      <div id="home">
        <HeaderSlider />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="description">
        <Description />
      </div>

      <div id="contact">
        <FormContact />
      </div>
    </div>
  );
}

export default HomePageVitrine;
