import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from './../../components/Button/Button';
import { updateCompetition, getCompetitionById } from './../../services/competitionsServices';
import { toast } from 'react-toastify';
import { useUser } from './../../contexts/UserContext';

const EditCompetitionModal = ({ isOpen, onClose, refreshList, competitionId }) => {
    const { user } = useUser();
    const userId = user?.userId; // ID de l'utilisateur connecté

    const [competitionData, setCompetitionData] = useState({
        nom_competition: '',
        level_competition: '',
        lieu_competition: '',
        date_competition: '',
        organisateur_competition: userId, // Organisateur par défaut est l'utilisateur connecté
        is_active: true,
    });

    useEffect(() => {
        if (isOpen && competitionId) {
            const fetchCompetitionData = async () => {
                try {
                    const data = await getCompetitionById(competitionId);
                    setCompetitionData(data);
                } catch (error) {
                    console.error("Erreur lors du chargement de la compétition:", error);
                    toast.error("Erreur lors du chargement des données de la compétition");
                }
            };
            fetchCompetitionData();
        }
    }, [isOpen, competitionId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCompetitionData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateCompetition(competitionId, competitionData);
            toast.success("Compétition mise à jour avec succès");
            refreshList(); // Rafraîchir la liste des compétitions après succès
            onClose(); // Fermer la modale après succès
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la compétition:", error);
            toast.error("Erreur lors de la mise à jour de la compétition");
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
                <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Éditer une Compétition</h3>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-sm font-bold mb-2">Nom de la compétition:</label>
                        <input
                            className="input input-bordered w-full"
                            type="text"
                            name="nom_competition"
                            value={competitionData.nom_competition}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Niveau:</label>
                        <input
                            className="input input-bordered w-full"
                            type="text"
                            name="level_competition"
                            value={competitionData.level_competition}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Lieu:</label>
                        <input
                            className="input input-bordered w-full"
                            type="text"
                            name="lieu_competition"
                            value={competitionData.lieu_competition}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Date:</label>
                        <input
                            className="input input-bordered w-full"
                            type="date"
                            name="date_competition"
                            value={competitionData.date_competition?.split('T')[0]}  // Date formatée
                            onChange={handleChange}
                            required
                        />
                    </div>
                    {/* Checkbox pour l'activation de la compétition */}
                    <div className="flex items-center">
                        <input
                            className="form-checkbox text-primary"
                            type="checkbox"
                            name="is_active"
                            checked={competitionData.is_active}
                            onChange={(e) => setCompetitionData(prev => ({ ...prev, is_active: e.target.checked }))}
                        />
                        <label className="ml-2">Activer la compétition</label>
                    </div>
                    <div className="flex justify-center space-x-2 mt-4">
                        <Button text="Mettre à jour" icon={FaSave} type="submit" className="btn-primary gap-2" />
                        <Button text="Fermer" icon={FaTimes} onClick={onClose} className="btn-ghost gap-2" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditCompetitionModal;
