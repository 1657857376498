import React, { useState, useEffect } from "react";
import { createCombat, updateCombat } from "../services/combatsServices"; // Importer les deux fonctions

const CombatTableauModal = ({
  isOpen,
  onClose,
  competiteur1,
  competiteur2,
  onSubmit, // Fonction à appeler lors de la soumission
  initialData, // Les données initiales du combat, s'il s'agit d'une modification
  distributionId, // Assurez-vous que distributionId est bien passé ici
  position1,
  position2,
}) => {
  const [resultatCompetiteur1, setResultatCompetiteur1] = useState(
    initialData?.resultat_competiteur1 || ""
  );
  const [resultatCompetiteur2, setResultatCompetiteur2] = useState(
    initialData?.resultat_competiteur2 || ""
  );
  const [pointsCompetiteur1, setPointsCompetiteur1] = useState(
    initialData?.points_competiteur1 || 0
  );
  const [pointsCompetiteur2, setPointsCompetiteur2] = useState(
    initialData?.points_competiteur2 || 0
  );

  // Réinitialiser les données à chaque ouverture de la modale
  useEffect(() => {
    if (isOpen) {
      setResultatCompetiteur1(initialData?.resultat_competiteur1 || "");
      setResultatCompetiteur2(initialData?.resultat_competiteur2 || "");
      setPointsCompetiteur1(initialData?.points_competiteur1 || 0);
      setPointsCompetiteur2(initialData?.points_competiteur2 || 0);
      console.log("Modal opened with distributionId:", distributionId); // Vérifier ici que distributionId est bien reçu
    }
  }, [isOpen, initialData]);

  const handleSubmit = async () => {
    const combatData = {
      competiteur_id1: competiteur1.id,
      competiteur_id2: competiteur2?.id || null, // Gérer l'exemption
      distribution_id: distributionId, // Vérifier ici que distributionId est bien passé
      resultat_competiteur1: resultatCompetiteur1,
      resultat_competiteur2: resultatCompetiteur2,
      points_competiteur1: parseInt(pointsCompetiteur1, 10),
      points_competiteur2: parseInt(pointsCompetiteur2, 10),
    };

    console.log("Submitting combat data:", combatData); // Vérifie que distribution_id est bien défini avant soumission

    try {
      if (initialData?.id) {
        console.log("Updating existing combat with ID:", initialData.id);
        await updateCombat(initialData.id, combatData);
      } else {
        console.log("Creating new combat");
        await createCombat(combatData);
      }

      onSubmit(combatData); // Appeler la fonction de soumission après succès
      onClose(); // Fermer la modale après la soumission
    } catch (error) {
      console.error("Erreur lors de la soumission du combat :", error); // Vérifie si une erreur survient
    }
  };

  const renderResultOptions = (resultat, setResultat) => {
    return (
      <div className="flex justify-between space-x-2">
        {["V", "D", "N", "F", "H"].map((option) => (
          <button
            key={option}
            className={`px-4 py-2 rounded ${
              resultat === option
                ? "bg-blue-500 text-white shadow-xl border border-gray-600"
              : "bg-gray-200 shadow-xl border border-gray-600"
            }`}
            onClick={() => setResultat(option)}
          >
            {option}
          </button>
        ))}
      </div>
    );
  };

  if (!isOpen || !competiteur1 || !competiteur2) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4">
        <h2 className="text-xl font-bold mb-4">Résultats du Combat en Tableau</h2>
        <div className="grid grid-cols-2 gap-4">
          {/* Bloc du 1er compétiteur */}
          <div className="bg-red-100 p-4 rounded-lg border border-gray-300">
            <h3 className="text-lg font-bold mb-2">
              {position1} - {competiteur1?.nom_competiteur}{" "}
              {competiteur1?.prenom_competiteur}
            </h3>
            <div className="mb-4">
              <label className="block font-bold mb-1">Résultats</label>
              {renderResultOptions(resultatCompetiteur1, setResultatCompetiteur1)}
            </div>
            <div className="mb-4">
              <label className="block font-bold mb-1">Points</label>
              <input
                type="number"
                value={pointsCompetiteur1}
                onChange={(e) => setPointsCompetiteur1(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
          </div>

          {/* Bloc du 2ème compétiteur */}
          {competiteur2 ? (
            <div className="bg-white p-4 rounded-lg border border-gray-300">
              <h3 className="text-lg font-bold mb-2">
                {position2} - {competiteur2?.nom_competiteur}{" "}
                {competiteur2?.prenom_competiteur}
              </h3>
              <div className="mb-4">
                <label className="block font-bold mb-1">Résultats</label>
                {renderResultOptions(resultatCompetiteur2, setResultatCompetiteur2)}
              </div>
              <div className="mb-4">
                <label className="block font-bold mb-1">Points</label>
                <input
                  type="number"
                  value={pointsCompetiteur2}
                  onChange={(e) => setPointsCompetiteur2(e.target.value)}
                  className="border p-2 w-full"
                />
              </div>
            </div>
          ) : (
            <div className="bg-white p-4 rounded-lg border border-gray-300">
              <h3 className="text-lg font-bold mb-2">Exempt (saute un tour)</h3>
            </div>
          )}
        </div>

        <div className="flex justify-end space-x-4 mt-4">
          <button className="bg-red-500 text-white px-4 py-2 rounded" onClick={onClose}>
            Annuler
          </button>
          <button className="bg-green-500 text-white px-4 py-2 rounded" onClick={handleSubmit}>
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  );
};

export default CombatTableauModal;
