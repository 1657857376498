import React, { useState, useEffect } from "react";
import { updateCombat } from "../services/combatsServices"; // Importer le service pour mettre à jour un combat

const EditCombatModal = ({
  isOpen,
  onClose,
  competiteur1,
  competiteur2,
  initialData,
  distributionId,
  position1,
  position2,
  refreshPouleTable,
}) => {
  const [resultatCompetiteur1, setResultatCompetiteur1] = useState("");
  const [resultatCompetiteur2, setResultatCompetiteur2] = useState("");
  const [pointsCompetiteur1, setPointsCompetiteur1] = useState(0);
  const [pointsCompetiteur2, setPointsCompetiteur2] = useState(0);

  useEffect(() => {
  if (isOpen) {
    setResultatCompetiteur1(initialData?.resultat_competiteur1 || "");
    setResultatCompetiteur2(initialData?.resultat_competiteur2 || "");
    setPointsCompetiteur1(initialData?.points_competiteur1 || 0);
    setPointsCompetiteur2(initialData?.points_competiteur2 || 0);
  }
}, [isOpen, initialData]);

useEffect(() => {
  if (isOpen) {
    console.log("isOpen:", isOpen);
    console.log("competiteur1:", competiteur1);
    console.log("competiteur2:", competiteur2);
    console.log("initialData:", initialData);
  }
}, [isOpen, competiteur1, competiteur2, initialData]);


  const handleSubmit = async () => {
  try {
    const combatData = {
      competiteur_id1: competiteur1.id,
      competiteur_id2: competiteur2.id,
      distribution_id: distributionId,
      resultat_competiteur1: resultatCompetiteur1,
      resultat_competiteur2: resultatCompetiteur2,
      points_competiteur1: parseInt(pointsCompetiteur1, 10),
      points_competiteur2: parseInt(pointsCompetiteur2, 10),
    };

    await updateCombat(initialData.id, combatData);

    refreshPouleTable(); // Rafraîchir le tableau après la soumission
    onClose();
  } catch (error) {
    console.error("Erreur lors de la mise à jour du combat :", error);
  }
};


  const renderResultButtons = (resultat, setResultat) => (
    <div className="flex justify-between">
      {["V", "D", "N", "F", "H"].map((option) => (
        <button
          key={option}
          className={`px-4 py-2 rounded ${
            resultat === option
              ? "bg-blue-500 text-white shadow-xl border border-gray-600"
              : "bg-gray-200 shadow-xl border border-gray-600"
          }`}
          onClick={() => setResultat(option)}
        >
          {option}
        </button>
      ))}
    </div>
  );

  if (!isOpen || !competiteur1 || !competiteur2) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4">
        <h2 className="text-xl font-bold mb-4">Éditer le Combat</h2>
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-red-100 p-4 rounded-lg border border-gray-300">
            <h3 className="text-lg font-bold mb-2">
              {position1} - {competiteur1.nom_competiteur} {competiteur1.prenom_competiteur}
            </h3>
            <div className="mb-4">
              <label className="block font-bold mb-1">Résultats</label>
              {renderResultButtons(resultatCompetiteur1, setResultatCompetiteur1)}
            </div>
            <div className="mb-4">
              <label className="block font-bold mb-1">Points</label>
              <input
                type="number"
                value={pointsCompetiteur1}
                onChange={(e) => setPointsCompetiteur1(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
          </div>

          <div className="bg-white p-4 rounded-lg border border-gray-300">
            <h3 className="text-lg font-bold mb-2">
              {position2} - {competiteur2.nom_competiteur} {competiteur2.prenom_competiteur}
            </h3>
            <div className="mb-4">
              <label className="block font-bold mb-1">Résultats</label>
              {renderResultButtons(resultatCompetiteur2, setResultatCompetiteur2)}
            </div>
            <div className="mb-4">
              <label className="block font-bold mb-1">Points</label>
              <input
                type="number"
                value={pointsCompetiteur2}
                onChange={(e) => setPointsCompetiteur2(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-4 mt-4">
          <button className="bg-red-500 text-white px-4 py-2 rounded" onClick={onClose}>
            Annuler
          </button>
          <button className="bg-green-500 text-white px-4 py-2 rounded" onClick={handleSubmit}>
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCombatModal;
