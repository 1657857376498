import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import {
  getUserCompetitions,
  addCompetition,
  deleteCompetition,
} from "../../services/competitionsServices";
import Button from "./../../components/Button/Button";
import ButtonEdit from "./../../components/Button/ButtonEdit";
import ButtonDelete from "./../../components/Button/ButtonDelete";
import useScrollToTop from "../../hooks/useScrollToTop";
import { useUser } from "./../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../utils/roleHelper";
import { useNavigate } from "react-router-dom";
import ScrollIndicator from "./../../hooks/ScrollIndicator";
import EditCompetitionAdminModal from "./EditCompetitionModal";
import { ToastContainer, toast } from "react-toastify";

const MAX_COMPETITIONS = 2; // Limite de 2 compétitions par utilisateur

const CompetitionPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userId = user?.userId; // ID de l'utilisateur connecté
  const userRole = getRoleFromRoleId(user?.role_id);
  const navigate = useNavigate();
  const [competitions, setCompetitions] = useState([]);
  const [newCompetition, setNewCompetition] = useState({
    nom_competition: "",
    level_competition: "",
    lieu_competition: "",
    date_competition: "",
    organisateur_competition: userId,
    is_active: true,
  });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentCompetitionId, setCurrentCompetitionId] = useState(null);

  // Charger les compétitions créées par l'utilisateur connecté
  const fetchUserCompetitions = async () => {
    try {
      const competitionsData = await getUserCompetitions(userId);
      setCompetitions(competitionsData);
    } catch (error) {
      toast.error("Erreur lors du chargement des compétitions.");
    }
  };

  useEffect(() => {
    fetchUserCompetitions();
  }, [userId]);

  // Gérer la suppression d'une compétition
  const handleDeleteCompetition = async (id) => {
    if (
      window.confirm("Êtes-vous sûr de vouloir supprimer cette compétition ?")
    ) {
      try {
        await deleteCompetition(id);
        toast.success("Compétition supprimée avec succès");
        fetchUserCompetitions(); // Recharger les compétitions après suppression
      } catch (error) {
        toast.error("Erreur lors de la suppression de la compétition");
      }
    }
  };

  // Ouvrir la modale d'édition
  const handleOpenEditModal = (competitionId) => {
    setCurrentCompetitionId(competitionId);
    setEditModalOpen(true);
  };

  // Gérer la soumission du formulaire pour ajouter une nouvelle compétition
  const handleAddCompetition = async (e) => {
    e.preventDefault();
    if (competitions.length >= MAX_COMPETITIONS) {
      toast.error("Vous avez atteint la limite de 2 compétitions.");
      return;
    }
    try {
      await addCompetition(newCompetition);
      toast.success("Compétition ajoutée avec succès");

      // Réinitialiser le formulaire et recharger les compétitions après l'ajout
      setNewCompetition({
        nom_competition: "",
        level_competition: "",
        lieu_competition: "",
        date_competition: "",
        organisateur_competition: userId,
        is_active: true,
      });
      fetchUserCompetitions();
    } catch (error) {
      toast.error("Erreur lors de l'ajout de la compétition");
    }
  };

  // Gérer les changements dans le formulaire d'ajout
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCompetition({
      ...newCompetition,
      [name]: value,
    });
  };

  // Fonction pour naviguer vers la page d'une compétition
  const handleViewCompetition = (id) => {
    navigate(`/competition/${id}`); // Redirection vers le chemin de la compétition
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        CLIENT
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Mes Compétitions
      </h2>

      <div className="w-full mx-auto">
        {/* Formulaire pour ajouter une nouvelle compétition */}
        <form onSubmit={handleAddCompetition} className="mb-8">
          <h3 className="text-xl mb-4">Ajouter une nouvelle compétition</h3>
          {/* Avertissement si limite atteinte */}
          {competitions.length >= MAX_COMPETITIONS && (
            <div className="alert alert-warning mb-6">
              <span>Vous ne pouvez pas créer plus de 2 compétitions.</span>
            </div>
          )}
          <div className="grid grid-cols-2 gap-4 mb-8">
            <input
              type="text"
              name="nom_competition"
              placeholder="Nom de la compétition"
              value={newCompetition.nom_competition}
              onChange={handleInputChange}
              required
              className="input input-bordered"
            />
            <input
              type="text"
              name="level_competition"
              placeholder="Niveau"
              value={newCompetition.level_competition}
              onChange={handleInputChange}
              required
              className="input input-bordered"
            />
            <input
              type="text"
              name="lieu_competition"
              placeholder="Lieu"
              value={newCompetition.lieu_competition}
              onChange={handleInputChange}
              required
              className="input input-bordered"
            />
            <input
              type="date"
              name="date_competition"
              placeholder="Date"
              value={newCompetition.date_competition}
              onChange={handleInputChange}
              required
              className="input input-bordered"
            />
          </div>

          <div className="shadow-xl w-full max-w-[1280px] mx-auto">
            <Button
              text="Ajouter une Compétition"
              icon={FaPlus}
              className="w-full gap-2"
              disabled={competitions.length >= MAX_COMPETITIONS}
            />
          </div>
        </form>

        {/* Liste des compétitions sous forme de cartes */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {competitions.map((competition) => (
            <div
              key={competition.id}
              className="card bg-white dark:bg-gray-800 shadow-md flex flex-col lg:flex-row p-4"
            >
              {/* Image en haut sur mobile et à gauche sur desktop */}
              <img
                className="lg:w-1/3 w-full lg:h-auto h-32 object-cover mb-4 lg:mb-0 lg:mr-4"
                src="https://res.cloudinary.com/dy5kblr32/image/upload/v1715603804/images/events/63dbf43a9207a_TournoijudointerclubsdeRambouillet28jan202356_eyuslm.jpg" // Image par défaut pour la compétition
                alt="Compétition"
              />
              <div className="flex-grow">
                <h3 className="text-lg font-bold">
                  {competition.nom_competition}
                </h3>
                <p>
                  <strong>Niveau :</strong> {competition.level_competition}
                </p>
                <p>
                  <strong>Lieu :</strong> {competition.lieu_competition}
                </p>
                <p>
                  <strong>Date :</strong>{" "}
                  {new Date(competition.date_competition).toLocaleDateString()}
                </p>
                <div className="flex justify-between items-center mt-4">
                  <span className="text-sm text-gray-600">
                    {competition.is_active ? "✅ Active" : "❌ Inactive"}
                  </span>
                  <div className="flex gap-2">
                    <ButtonEdit
                      onClick={() => handleOpenEditModal(competition.id)}
                    />
                    <ButtonDelete
                      onClick={() => handleDeleteCompetition(competition.id)}
                    />
                    <Button
                      text="Voir"
                      onClick={() => handleViewCompetition(competition.id)} // Bouton pour accéder à la compétition
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modale d'édition */}
      <EditCompetitionAdminModal
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        refreshList={fetchUserCompetitions}
        competitionId={currentCompetitionId}
      />

      <ToastContainer />
    </div>
  );
};

export default CompetitionPage;
