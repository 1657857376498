import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import backgroundImage from "./HeaderSlider_02.jpg"; // Assurez-vous que cette ligne est correcte
import useScrollToTop from "./../../hooks/useScrollToTop";
import { registerUser, checkEmailExists } from "../../services/userServices";
import { sendNotificationToAdmin } from "../../services/notificationServices";
import Modal from "./../../components/Modal";
import Button from "./../../components/Button/Button";

function RegisterPage() {
  useScrollToTop();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailCheck = await checkEmailExists(email);
    if (emailCheck.error) {
      setErrorMessage(emailCheck.error);
      return;
    }
    if (emailCheck.exists) {
      openModal(); // Cette fonction doit gérer l'ouverture de votre modal
    } else {
      const result = await registerUser(nom, prenom, email, password);
      if (result.error) {
        setErrorMessage(result.error);
      } else {
        console.log("Réponse de l'API:", result.data);

        // Envoyer une notification à l'administrateur
        const token = localStorage.getItem("token");
        const message = `Un nouvel utilisateur a été créé : ${nom} ${prenom} (${email})`;
        await sendNotificationToAdmin(message, token);

        navigate("/login");
      }
    }
  };

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleResetPassword = () => {
    closeModal();
    navigate("/forgot-password");
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
      }}
    >
      <div className="max-w-md w-full space-y-8 bg-white bg-opacity-90 p-10 rounded-lg shadow-lg">
        {errorMessage && (
          <div className="alert alert-error shadow-lg">
            <div>
              <span>{errorMessage}</span>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-6">
          <h1 className="text-center text-3xl font-bold">Inscription</h1>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Nom</span>
            </label>
            <input
              type="text"
              placeholder="Votre nom"
              className="input input-bordered w-full"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              required
            />
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Prénom</span>
            </label>
            <input
              type="text"
              placeholder="Votre prénom"
              className="input input-bordered w-full"
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              required
            />
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Email</span>
            </label>
            <input
              type="email"
              placeholder="Votre email"
              className="input input-bordered w-full"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Mot de passe</span>
            </label>
            <input
              type="password"
              placeholder="Choisissez un mot de passe"
              className="input input-bordered w-full"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            className="w-full bg-primary text-white px-4 py-2 rounded-md shadow-sm transition duration-300 ease-in-out hover:bg-white hover:text-primary border border-primary flex items-center justify-center"
            type="submit"
          >
            S'inscrire
          </button>
        </form>
      </div>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <p>
            Le mail est déjà enregistré. Souhaitez-vous réinitialiser votre mot
            de passe ?
          </p>
          <div className="flex justify-center gap-4">
            <Button text="Oui" onClick={handleResetPassword} />
            <Button text="Non" onClick={closeModal} />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default RegisterPage;
