import React, { useState, useEffect } from "react";
import CombatModal from "./../CombatModal";
import EditCombatModal from "./../EditCombatModal";
import { getDistributionCombats } from "../../services/combatsServices";
import { calculateClassementPoule } from "../../utils/classementPouleUtils"; // Importer l'utilitaire

const RepartitionPouleTrois = ({ competiteurs, distributionId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCombat, setSelectedCombat] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [combats, setCombats] = useState([]);
  const [classement, setClassement] = useState([]); // Contient le classement, mais reste vide tant que le bouton n'est pas cliqué
  const [classementVisible, setClassementVisible] = useState(false); // Gère l'affichage du classement
  const [positions, setPositions] = useState({
    position1: null,
    position2: null,
  });

  // Appel API pour récupérer les combats de la distribution
  const fetchCombats = async () => {
    try {
      const fetchedCombats = await getDistributionCombats(distributionId);
      setCombats(fetchedCombats); // Stocker les combats récupérés
      updateClassement(fetchedCombats); // Mettre à jour victoires et points
    } catch (error) {
      console.error("Erreur lors de la récupération des combats :", error);
    }
  };

  useEffect(() => {
    fetchCombats();
  }, [distributionId]);

  const refreshPouleTable = () => {
    fetchCombats(); // Recharger les combats après un changement
  };

  // Fonction pour mettre à jour les victoires et les points
  const updateClassement = (fetchedCombats) => {
    const classementCalc = calculateClassementPoule(
      competiteurs,
      fetchedCombats
    );
    setClassement(classementCalc); // Mettre à jour le classement
  };

  // Fonction pour afficher le classement uniquement quand le bouton est cliqué
  const handleShowClassement = () => {
    setClassementVisible(true); // Rendre le classement visible
  };

  // Fonction pour récupérer un combat existant
  const getCombatResult = (competiteur1Id, competiteur2Id) => {
    return combats.find(
      (combat) =>
        (combat.competiteur_id1 === competiteur1Id &&
          combat.competiteur_id2 === competiteur2Id) ||
        (combat.competiteur_id1 === competiteur2Id &&
          combat.competiteur_id2 === competiteur1Id)
    );
  };

  // Ouvrir la modale avec les données du combat existant ou initiales pour un nouveau combat
  const openModal = (competiteur1, competiteur2, position1, position2) => {
    const existingCombat = getCombatResult(competiteur1.id, competiteur2.id);
    const initialCombat = existingCombat
      ? existingCombat
      : {
          competiteur1: competiteur1,
          competiteur2: competiteur2,
        };

    setSelectedCombat(initialCombat); // Définir le combat (nouveau ou existant)
    setPositions({ position1, position2 });
    setIsModalOpen(true);
  };

  // Ouvrir la modale d'édition pour un combat existant
  const openEditModal = (combat, position1, position2) => {
    // Récupérer les compétiteurs depuis leurs IDs
    const competiteur1 = competiteurs.find(
      (c) => c.id === combat.competiteur_id1
    );
    const competiteur2 = competiteurs.find(
      (c) => c.id === combat.competiteur_id2
    );

    // Définir le combat à éditer et les compétiteurs
    setSelectedCombat({
      ...combat,
      competiteur1,
      competiteur2,
    });

    setPositions({ position1, position2 });
    setIsEditModalOpen(true);
  };

  const handleModalSubmit = async (combatResults) => {
    await refreshPouleTable(); // Recharger les résultats des combats après le submit
  };

  // Fonction pour afficher le classement avec gestion des disqualifications et ex æquo
  const renderClassement = (competiteur) => {
    if (classementVisible) {
      const competiteurClassement = classement.find(
        (c) => c.nom_competiteur === competiteur.nom_competiteur
      );

      if (!competiteurClassement) return ""; // Sécurité si aucune donnée de classement n'est trouvée

      if (competiteurClassement.disqualifie) {
        // Retourne "Disq." pour les compétiteurs disqualifiés
        return "Disq.";
      }

      // Vérifie s'il est ex-aequo
      const position = competiteurClassement.position;
      return competiteurClassement.exAequo
        ? `${position} (ex æquo)`
        : position;
    }
    return ""; // Classement vide tant que le bouton n'a pas été cliqué
  };

  const renderCombatResult = (
    competiteur1,
    competiteur2,
    rowIndex,
    colIndex
  ) => {
    if (competiteur1.id === competiteur2.id) {
      return (
        <td
          key={`${rowIndex}-${colIndex}`}
          className="border px-4 py-2 bg-black"
        ></td>
      );
    }

    const combat = getCombatResult(competiteur1.id, competiteur2.id);

    if (combat) {
      const formatResult = (resultat, points) => (
        <span>
          <strong className="text-base font-bold">{resultat}</strong>{" "}
          <small className="text-xs">({points})</small>
        </span>
      );

      const getColor = (resultat) => {
        switch (resultat) {
          case "V":
            return "bg-green-200";
          case "D":
            return "bg-red-200";
          case "N":
            return "bg-yellow-200";
          case "H":
            return "bg-red-500 text-white";
          default:
            return "";
        }
      };

      const result =
        competiteur1.id === combat.competiteur_id1
          ? formatResult(
              combat.resultat_competiteur1,
              combat.points_competiteur1
            )
          : formatResult(
              combat.resultat_competiteur2,
              combat.points_competiteur2
            );
      const color =
        competiteur1.id === combat.competiteur_id1
          ? getColor(combat.resultat_competiteur1)
          : getColor(combat.resultat_competiteur2);

      return (
        <td
          key={`${rowIndex}-${colIndex}`}
          className={`border px-4 py-2 ${color} text-center`}
        >
          {result}
        </td>
      );
    }

    return (
      <td
        key={`${rowIndex}-${colIndex}`}
        className="border px-4 py-2 text-center"
      ></td>
    );
  };

  // Vérifie si un combat a déjà eu lieu
  const isCombatDone = (competiteur1Id, competiteur2Id) =>
    !!getCombatResult(competiteur1Id, competiteur2Id);

  const handleCombatButtonClick = (
    competiteur1,
    competiteur2,
    position1,
    position2
  ) => {
    const existingCombat = getCombatResult(competiteur1.id, competiteur2.id);

    if (existingCombat) {
      openEditModal(existingCombat, position1, position2);
    } else {
      openModal(competiteur1, competiteur2, position1, position2);
    }
  };

  return (
    <div>
      <div className="flex items-center mb-4">
        <h2 className="text-xl font-bold">Tableau de Poule de 3</h2>
        <button
          onClick={refreshPouleTable}
          className="ml-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Recharger
        </button>
        <button
          onClick={() => setClassementVisible(!classementVisible)}
          className="ml-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          {classementVisible ? 'Masquer Classement' : 'Afficher Classement'}
        </button>
      </div>
      <table className="table-auto border-collapse w-full">
        <thead>
          <tr className="bg-gray-400 text-gray-800 text-sm">
            <th className="border px-2 py-2">N°</th>
            <th className="border px-2 py-2">Compétiteurs</th>
            <th className="border px-2 py-2">Clubs</th>
            <th className="border px-2 py-2">Poids</th>
            <th className="border px-2 py-2">1</th>
            <th className="border px-2 py-2">2</th>
            <th className="border px-2 py-2">3</th>
            <th className="border px-2 py-2">V</th>
            <th className="border px-2 py-2">Pts</th>
            <th className="border px-2 py-2">Class.</th>
          </tr>
        </thead>
        <tbody>
          {competiteurs.map((competiteur, rowIndex) => (
            <tr key={competiteur.id}>
              <td className="border px-2 py-2 text-center text-sm font-semibold">
                {rowIndex + 1}
              </td>
              <td className="border px-2 py-2 text-left text-sm font-semibold">
                {competiteur.nom_competiteur} {competiteur.prenom_competiteur}
              </td>
              <td className="border px-2 py-2 text-left text-xs font-thin">
                {competiteur.club_competiteur}
              </td>
              <td className="border px-2 py-2 text-center text-xs font-thin">
                {competiteur.poids_competiteur}
              </td>

              {competiteurs.map((opposant, colIndex) =>
                renderCombatResult(competiteur, opposant, rowIndex, colIndex)
              )}

              <td className="border px-2 py-2 text-center text-sm font-semibold">
                {classement.find(
                  (c) => c.nom_competiteur === competiteur.nom_competiteur
                )?.victoires || 0}
              </td>
              <td className="border px-2 py-2 text-center text-sm font-semibold">
                {classement.find(
                  (c) => c.nom_competiteur === competiteur.nom_competiteur
                )?.points || 0}
              </td>
              <td
                className={`border px-2 py-2 text-center text-sm font-semibold ${
                  classement.find(
                    (c) => c.nom_competiteur === competiteur.nom_competiteur
                  )?.disqualifie
                    ? "bg-gray-300"
                    : ""
                }`}
              >
                {renderClassement(competiteur)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-4">
        <button
          className={`px-4 py-2 rounded text-white ${
            isCombatDone(competiteurs[0].id, competiteurs[1].id)
              ? "bg-green-500"
              : "bg-blue-500"
          }`}
          onClick={() =>
            handleCombatButtonClick(competiteurs[0], competiteurs[1], 1, 2)
          }
        >
          1x2
        </button>
        <button
          className={`px-4 py-2 rounded text-white ml-4 ${
            isCombatDone(competiteurs[1].id, competiteurs[2].id)
              ? "bg-green-500"
              : "bg-blue-500"
          }`}
          onClick={() =>
            handleCombatButtonClick(competiteurs[1], competiteurs[2], 2, 3)
          }
        >
          2x3
        </button>
        <button
          className={`px-4 py-2 rounded text-white ml-4 ${
            isCombatDone(competiteurs[0].id, competiteurs[2].id)
              ? "bg-green-500"
              : "bg-blue-500"
          }`}
          onClick={() =>
            handleCombatButtonClick(competiteurs[0], competiteurs[2], 1, 3)
          }
        >
          1x3
        </button>
      </div>

      <CombatModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        competiteur1={selectedCombat?.competiteur1}
        competiteur2={selectedCombat?.competiteur2}
        onSubmit={handleModalSubmit}
        initialData={selectedCombat || {}}
        distributionId={distributionId}
        position1={positions.position1}
        position2={positions.position2}
        refreshPouleTable={refreshPouleTable}
      />
      <EditCombatModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        competiteur1={selectedCombat?.competiteur1}
        competiteur2={selectedCombat?.competiteur2}
        initialData={selectedCombat}
        distributionId={distributionId}
        position1={positions.position1}
        position2={positions.position2}
        refreshPouleTable={refreshPouleTable}
      />
    </div>
  );
};

export default RepartitionPouleTrois;
