import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getCompetitionById } from "../../services/competitionsServices";
import { getAllUsers } from "../../services/userServices";
import { getCompetitionCompetiteurs, toggleCompetiteurPresence, deleteCompetiteur, changeCompetiteurPoids  } from "../../services/competiteursServices";
import { useUser } from "./../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../utils/roleHelper";
import { ToastContainer, toast } from "react-toastify";
import Button from "./../../components/Button/Button";
import ButtonEdit from "./../../components/Button/ButtonEdit";
import ButtonDelete from "./../../components/Button/ButtonDelete";
import ButtonPoids from "./../../components/Button/ButtonPoids";
import getCategory from "../../utils/getCategory";
import getWeightCategory from "../../utils/getWeightCategory";
import EditCompetiteurModal from "./EditCompetiteurModal"; // Importation de la modal d'édition
import useScrollToTop from "../../hooks/useScrollToTop";
import ScrollIndicator from "./../../hooks/ScrollIndicator";

const CompetitionByIdPage = () => {
  useScrollToTop();
  const { id } = useParams();
  const { user } = useUser();
  const navigate = useNavigate();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;

  const [competition, setCompetition] = useState(null);
  const [competiteurs, setCompetiteurs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [organisateur, setOrganisateur] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Pour ouvrir/fermer la modal d'édition
  const [competiteurToEdit, setCompetiteurToEdit] = useState(null); // Stocker le compétiteur à éditer

  // États pour les filtres
  const [searchTerm, setSearchTerm] = useState("");
  const [filterPresence, setFilterPresence] = useState("all");
  const [filterGender, setFilterGender] = useState("all");
  const [filterAgeCategory, setFilterAgeCategory] = useState("all");
  const [filterWeightCategory, setFilterWeightCategory] = useState("all");
  const [filterYear, setFilterYear] = useState("all"); // État pour l'année de naissance



  const resetFilters = () => {
  setSearchTerm("");
  setFilterPresence("all");
  setFilterGender("all");
  setFilterAgeCategory("all");
  setFilterWeightCategory("all");
  setFilterYear("all");
};

  // Nouvel état pour la gestion de l'édition de poids
  const [editingPoidsId, setEditingPoidsId] = useState(null);
  const [newPoids, setNewPoids] = useState("");


  const checkPermissions = () => {
    if (userRole !== "admin" && competition?.organisateur_competition !== userId) {
      toast.error("Vous n'avez pas l'autorisation d'accéder à cette page.");
      navigate("/");
    }
  };

  const fetchCompetition = async () => {
    try {
      const data = await getCompetitionById(id);
      setCompetition(data);
      setLoading(false);
    } catch (error) {
      toast.error("Erreur lors du chargement de la compétition.");
      navigate("/");
    }
  };

  const fetchCompetiteurs = async () => {
    try {
      const competiteursData = await getCompetitionCompetiteurs(id);
      setCompetiteurs(competiteursData);
    } catch (error) {
      toast.error("Erreur lors du chargement des compétiteurs.");
    }
  };

  const fetchUsers = async () => {
    try {
      const usersData = await getAllUsers();
      const userOrganisateur = usersData.find(
        (user) => user.id === competition?.organisateur_competition
      );
      setOrganisateur(userOrganisateur);
    } catch (error) {
      toast.error("Erreur lors du chargement des utilisateurs.");
    }
  };



  useEffect(() => {
    fetchCompetition();
    fetchCompetiteurs();
  }, [id]);

  useEffect(() => {
    if (competition) {
      checkPermissions();
      fetchUsers();
    }
  }, [competition, userRole, userId]);



  const handleTogglePresence = async (competiteurId) => {
    try {
      await toggleCompetiteurPresence(competiteurId);
      fetchCompetiteurs();
    } catch (error) {
      toast.error("Erreur lors de la mise à jour de la présence.");
    }
  };

  const handleEditClick = (competiteur) => {
    setCompetiteurToEdit(competiteur);
    setIsEditModalOpen(true); // Ouvrir la modal d'édition
  };

  const handleDeleteClick = async (competiteurId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce compétiteur ?")) {
      try {
        await deleteCompetiteur(competiteurId);
        toast.success("Compétiteur supprimé avec succès.");
        fetchCompetiteurs(); // Recharger la liste après la suppression
      } catch (error) {
        toast.error("Erreur lors de la suppression du compétiteur.");
      }
    }
  };

  const handleEditPoidsClick = (competiteurId, currentPoids) => {
    setEditingPoidsId(competiteurId);
    setNewPoids(currentPoids || ""); // Définit le poids actuel ou vide si null
  };

  const handlePoidsChange = (e) => {
    setNewPoids(e.target.value);
  };

  const handleSavePoids = async (competiteurId) => {
    try {
      await changeCompetiteurPoids(competiteurId, newPoids); // Appel API pour mettre à jour le poids
      toast.success("Poids mis à jour avec succès.");
      setEditingPoidsId(null); // Fermer l'input après modification
      fetchCompetiteurs(); // Rafraîchir la liste des compétiteurs
    } catch (error) {
      toast.error("Erreur lors de la mise à jour du poids.");
    }
  };

  if (loading) {
    return <p>Chargement...</p>;
  }

  if (!competition || !organisateur) {
    return <p>Erreur lors du chargement des données.</p>;
  }

  // Appliquer les filtres aux compétiteurs
const filteredCompetiteurs = competiteurs.filter((competiteur) => {
  const ageCategory = getCategory(competiteur.ddn_competiteur);
  const weightCategory = getWeightCategory(
    competiteur.genre_competiteur,
    ageCategory,
    competiteur.poids_competiteur
  );
  const birthYear = new Date(competiteur.ddn_competiteur).getFullYear();

  return (
    (competiteur.nom_competiteur.toLowerCase().includes(searchTerm.toLowerCase()) ||
      competiteur.prenom_competiteur.toLowerCase().includes(searchTerm.toLowerCase()) ||
      competiteur.club_competiteur.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (filterPresence === "all" ||
      (filterPresence === "present" && competiteur.is_present) ||
      (filterPresence === "absent" && !competiteur.is_present)) &&
    (filterGender === "all" || competiteur.genre_competiteur === filterGender) &&
    (filterAgeCategory === "all" || ageCategory === filterAgeCategory) &&
    (filterWeightCategory === "all" || weightCategory === filterWeightCategory) &&
    (filterYear === "all" || birthYear.toString() === filterYear)
  );
});


  // Extraire les catégories de poids uniques des compétiteurs filtrés
  const uniqueWeightCategories = [
    ...new Set(
      filteredCompetiteurs.map((competiteur) => {
        const ageCategory = getCategory(competiteur.ddn_competiteur);
        return getWeightCategory(competiteur.genre_competiteur, ageCategory, competiteur.poids_competiteur);
      })
    ),
  ];

  

  return (
    <div className="flex flex-col w-full mx-auto p-4 mt-[130px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        Détails de la Compétition
      </h2>
      <div className="bg-white dark:bg-gray-800 shadow-md p-4 rounded-lg mx-auto">
        <h3 className="text-lg font-bold mb-4 font-lato">
          {competition.nom_competition}
        </h3>
        <p className="font-lato">
          <strong>Organisé par :</strong> {organisateur.nom} {organisateur.prenom}
        </p>
        <Button
  text="Répartition des compétiteurs"
  onClick={() => navigate(`/competition/${id}/repartition`)}
  className="mt-4"
/>
        <Button
  text="Suivi des combats"
  onClick={() => navigate(`/competition/${id}/suivi-combats`)}
  className="mt-4"
/>

        {/* Filtres de recherche */}
          <div className="mt-4 mb-4 font-lato">
            <input
              type="text"
              placeholder="Rechercher un compétiteur"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border p-2 w-full mb-4"
            />
            <div className="flex flex-col sm:flex-row gap-2">
              <select value={filterPresence} onChange={(e) => setFilterPresence(e.target.value)} className="border p-2 sm:w-1/5 w-full">
                <option value="all">Tous</option>
                <option value="present">Présent</option>
                <option value="absent">Absent</option>
              </select>
              <select value={filterGender} onChange={(e) => setFilterGender(e.target.value)} className="border p-2 sm:w-1/5 w-full">
                <option value="all">Tous Genres</option>
                <option value="M">Masculin</option>
                <option value="F">Féminin</option>
              </select>
              <select value={filterAgeCategory} onChange={(e) => setFilterAgeCategory(e.target.value)} className="border p-2 sm:w-1/5 w-full">
                <option value="all">Toutes Catégories d'âge</option>
                <option value="Eveil">Eveil</option>
                <option value="Mini-Poussin">Mini-Poussin</option>
                <option value="Poussin">Poussin</option>
                <option value="Benjamin">Benjamin</option>
                <option value="Minime">Minime</option>
                <option value="Cadet">Cadet</option>
                <option value="Junior">Junior</option>
                <option value="Sénior">Sénior</option>
              </select>
              <select
                value={filterYear}
                onChange={(e) => setFilterYear(e.target.value)}
                className="border p-2 sm:w-1/5 w-full"
              >
                <option value="all">Toutes Années de Naissance</option>
                {competiteurs
                  .map((competiteur) =>
                    new Date(competiteur.ddn_competiteur).getFullYear()
                  )
                  .filter((year, index, self) => self.indexOf(year) === index) // Filtrer les années uniques
                  .sort((a, b) => b - a) // Trier par ordre décroissant
                  .map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
              </select>
              <select value={filterWeightCategory} onChange={(e) => setFilterWeightCategory(e.target.value)} className="border p-2 sm:w-1/5 w-full">
                <option value="all">Toutes Catégories de poids</option>
                {uniqueWeightCategories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-start my-4">
              <Button text="Réinitialiser les filtres" onClick={resetFilters} className="btn-secondary" />
              </div>
          </div>


        <div className="overflow-x-auto mt-4">
          <table className="table-auto w-full bg-white shadow-md text-xs font-lato">
            <thead>
              <tr className="bg-gray-800 text-white">
                <th className="px-4 py-2">Présence</th>
                <th className="px-4 py-2">Nom</th>
                <th className="px-4 py-2">Prénom</th>
                <th className="px-4 py-2">Licence</th>
                <th className="px-4 py-2">DDN</th>
                <th className="px-4 py-2">Club</th>
                <th className="px-4 py-2">Genre</th>
                <th className="px-4 py-2">Poids (kg)</th>
                <th className="px-4 py-2">Catégorie d'âge</th>
                <th className="px-4 py-2">Catégorie de poids</th>
                <th className="px-4 py-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredCompetiteurs.map((competiteur) => {
                const ageCategory = getCategory(competiteur.ddn_competiteur);
                const weightCategory = getWeightCategory(
                  competiteur.genre_competiteur,
                  ageCategory,
                  competiteur.poids_competiteur
                );

                return (
                  <tr
                    key={competiteur.id}
                    className={`border px-4 py-2 ${
                      competiteur.genre_competiteur === "F" ? "bg-pink-200" : "bg-blue-200"
                    }`}
                  >
                    <td className="border px-4 py-2 text-center">
                      <button onClick={() => handleTogglePresence(competiteur.id)}>
                        {competiteur.is_present ? "✅" : "❌"}
                      </button>
                    </td>
                    <td className="border px-4 py-2">
                      {competiteur.nom_competiteur}
                    </td>
                    <td className="border px-4 py-2">{competiteur.prenom_competiteur}</td>
                    <td className="border px-4 py-2">{competiteur.licence_competiteur}</td>
                    <td className="border px-4 py-2">
                      {new Date(competiteur.ddn_competiteur).toLocaleDateString()}
                    </td>
                    <td className="border px-4 py-2">{competiteur.club_competiteur}</td>
                    <td className="border px-4 py-2">{competiteur.genre_competiteur}</td>
                    <td className="border px-4 py-2">
                      {editingPoidsId === competiteur.id ? (
                        <div className="flex items-center">
                          <input
                            type="number"
                            value={newPoids}
                            onChange={handlePoidsChange}
                            className="border p-1 w-[60px]"
                          />
                          <button
                            onClick={() => handleSavePoids(competiteur.id)}
                            className="ml-2 bg-blue-500 text-white p-1 rounded"
                          >
                            ✓
                          </button>
                        </div>
                      ) : (
                        <div className="flex items-center font-bold">
                          {competiteur.poids_competiteur !== null && competiteur.poids_competiteur !== 0 ? (
                            // Si le poids est défini (pas null et pas 0), affiche le poids avec une décimale
                            <span>{competiteur.poids_competiteur.toFixed(1)}</span>
                          ) : (
                            // Si le poids est null ou 0, affiche le bouton pour ajouter/modifier le poids
                            <ButtonPoids
                              onClick={() =>
                                handleEditPoidsClick(
                                  competiteur.id,
                                  competiteur.poids_competiteur
                                )
                              }
                              hoverLabel="Ajouter un poids"
                            />
                          )}
                        </div>
                      )}
                    </td>



                    <td className="border px-4 py-2">{ageCategory}</td>
                    <td className="border px-4 py-2">{weightCategory}</td>
                    <td className="border px-4 py-2 flex">
                      <ButtonEdit text="Éditer" onClick={() => handleEditClick(competiteur)} />
                      <ButtonDelete text="Supprimer" onClick={() => handleDeleteClick(competiteur.id)} className="ml-2 btn-danger" />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal d'édition */}
      <EditCompetiteurModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        competiteur={competiteurToEdit}
        refreshList={fetchCompetiteurs} // Rafraîchir la liste après modification
      />
      <ToastContainer />
    </div>
  );
};

export default CompetitionByIdPage;
