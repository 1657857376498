export const generateEliminationTable = (competiteurs) => {
  const totalSlots = getNextPowerOfTwo(competiteurs.length);
  const emptySlots = totalSlots - competiteurs.length;

  // Répartir les compétiteurs dans Tableau A et Tableau B
  const competiteursA = [];
  const competiteursB = [];

  competiteurs.forEach((competiteur, index) => {
    if (competiteursA.length < 6) {
      competiteursA.push(competiteur);
    } else {
      competiteursB.push(competiteur);
    }
  });

  const tableauA = createTableWithNulls(competiteursA, 8);
  const tableauB = createTableWithNulls(competiteursB, 8);

  return {
    tableauA,
    tableauB,
  };
};

// Fonction pour créer un tableau avec des "null" pour les compétiteurs exemptés
const createTableWithNulls = (competiteurs, slots) => {
  const tableau = [];
  let competiteursCounter = 0;

  for (let i = 0; i < slots; i++) {
    if (i % 2 !== 0) {
      tableau.push(null); // Ajouter des "null" aux bons endroits (saute un tour)
    } else {
      if (competiteursCounter < competiteurs.length) {
        tableau.push(competiteurs[competiteursCounter]);
        competiteursCounter++;
      } else {
        tableau.push(null); // Ajouter des "null" si tous les compétiteurs sont assignés
      }
    }
  }

  return tableau;
};

// Fonction pour obtenir la prochaine puissance de 2 supérieure ou égale à n
const getNextPowerOfTwo = (n) => {
  return Math.pow(2, Math.ceil(Math.log2(n)));
};
