// /utils/generateRandomPositions.js

/**
 * Génère des positions aléatoires pour les compétiteurs
 * @param {Array} competiteurs - Liste des compétiteurs
 * @returns {Array} - Liste des positions aléatoires
 */
const generateRandomPositions = (competiteurs) => {
  const positions = Array.from(
    { length: competiteurs.length },
    (_, i) => i + 1
  );
  for (let i = positions.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [positions[i], positions[j]] = [positions[j], positions[i]];
  }
  return positions;
};

export default generateRandomPositions;
