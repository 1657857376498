import React, { useState, useEffect } from "react";
import CombatTableauModal from "./../CombatTableauModal";
import EditCombatTableauModal from "./../EditCombatTableauModal";
import { getDistributionCombats } from "../../services/combatsServices";
import groupeVingtTrois from "./../../utils/schemas/groupeVingtTrois"; // Import du schéma de répartition

const RepartitionTableauDoubleRepechage = ({ competiteurs, distributionId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // État pour la modale d'édition
  const [selectedCombat, setSelectedCombat] = useState(null);
  const [positions, setPositions] = useState({ position1: null, position2: null });
  const [combats, setCombats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eliminationTable, setEliminationTable] = useState({});

  // Fonction pour récupérer les combats de la distribution
  const fetchCombats = async () => {
    setLoading(true);
    try {
      const fetchedCombats = await getDistributionCombats(distributionId);
      setCombats(fetchedCombats);

      // Utilisation de groupeVingtTrois pour générer le tableau d'élimination
      setEliminationTable(groupeVingtTrois(competiteurs));
    } catch (error) {
      console.error("Erreur lors de la récupération des combats :", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCombats();
  }, [distributionId]);

  // Fonction pour ouvrir la modale d'ajout d'un combat
  const openModal = (competiteur1, competiteur2, position1, position2) => {
    setSelectedCombat({
      competiteur1,
      competiteur2,
    });
    setPositions({ position1, position2 });
    setIsModalOpen(true);
  };

  // Fonction pour ouvrir la modale d'édition d'un combat existant
  const openEditModal = (combatData, competiteur1, competiteur2, position1, position2) => {
    setSelectedCombat({
      competiteur1,
      competiteur2,
      combatData,
    });
    setPositions({ position1, position2 });
    setIsEditModalOpen(true);
  };

  // Fonction appelée à la soumission d'un combat
  const handleCombatSubmit = async (combatData) => {
    await fetchCombats(); // Rafraîchir les combats après soumission
    setIsModalOpen(false); // Fermer la modale après la mise à jour des combats
  };

  // Fonction appelée à la soumission de l'édition d'un combat
  const handleEditSubmit = async (combatData) => {
    await fetchCombats(); // Rafraîchir les combats après soumission
    setIsEditModalOpen(false); // Fermer la modale après la mise à jour des combats
  };

  // Fonction pour obtenir le résultat d'un combat
  const getCombatResult = (competiteur1Id, competiteur2Id) => {
    return combats.find(
      (combat) =>
        (combat.competiteur_id1 === competiteur1Id && combat.competiteur_id2 === competiteur2Id) ||
        (combat.competiteur_id1 === competiteur2Id && combat.competiteur_id2 === competiteur1Id)
    );
  };

// Définir la couleur de fond en fonction du résultat
const getResultColor = (competiteurId, result) => {
  if (result === "V") return "bg-green-200"; // Victoire
  if (result === "D") return "bg-red-200"; // Défaite
  return ""; // Aucun résultat
};

  // Affichage des résultats du combat
  const renderCombatResultDetails = (combatResult) => {
  if (!combatResult) return null;

  return (
    <div className="text-center mt-2 flex justify-center">
      {/* Résultat pour le compétiteur 1 */}
      <span className={`${getResultColor(combatResult.competiteur_id1, combatResult.resultat_competiteur1)} p-1 rounded mr-2`}>
        {combatResult.resultat_competiteur1} ({combatResult.points_competiteur1})
      </span>
      vs
      {/* Résultat pour le compétiteur 2 */}
      <span className={`${getResultColor(combatResult.competiteur_id2, combatResult.resultat_competiteur2)} p-1 rounded ml-2`}>
        {combatResult.resultat_competiteur2} ({combatResult.points_competiteur2})
      </span>
    </div>
  );
};


  // Fonction pour afficher les combats avec un bouton pour ouvrir la modale
  const renderCombatCard = (competiteur1, competiteur2) => {
    if (!competiteur1 && !competiteur2) {
      return null; // Ne pas afficher si les deux compétiteurs sont exemptés
    }

    const combatResult = competiteur2 ? getCombatResult(competiteur1.id, competiteur2.id) : null;
    const resultCompetiteur1 = combatResult ? combatResult.resultat_competiteur1 : null;
    const resultCompetiteur2 = combatResult ? combatResult.resultat_competiteur2 : null;

    return (
      <div className="bg-white dark:bg-gray-800 shadow-md p-4 rounded-lg mb-4 flex justify-between items-center w-full">
        <div className={`w-1/3 text-center ${getResultColor(competiteur1?.id, resultCompetiteur1)}`}>

          <p className="text-lg font-bold">
            {competiteur1 ? competiteur1.nom_competiteur : "Exempt"}
          </p>
          <p className="text-sm italic font-light">
            {competiteur1 ? competiteur1.prenom_competiteur : ""}
          </p>
          <p className="text-sm italic">
            {competiteur1 ? competiteur1.club_competiteur : ""}
          </p>
        </div>

        {/* Bouton centré pour enregistrer ou éditer le résultat du combat */}
        {competiteur1 && competiteur2 && (
          <div className="w-1/3 flex flex-col justify-center items-center">
            {combatResult ? (
              <button
                className="bg-green-500 text-white px-4 py-2 rounded"
                onClick={() =>
                  openEditModal(combatResult, competiteur1, competiteur2, competiteur1.id, competiteur2.id)
                }
              >
                Éditer Résultat
              </button>
            ) : (
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={() =>
                  openModal(competiteur1, competiteur2, competiteur1.id, competiteur2.id)
                }
              >
                Enregistrer Résultat
              </button>
            )}

            {/* Affichage des résultats après le bouton */}
            {renderCombatResultDetails(combatResult)}
          </div>
        )}

        <div className={`w-1/3 text-center ${getResultColor(competiteur2?.id, resultCompetiteur2)}`}>

          <p className="text-lg font-bold">
            {competiteur2 ? competiteur2.nom_competiteur : "Exempt"}
          </p>
          <p className="text-sm italic font-light">
            {competiteur2 ? competiteur2.prenom_competiteur : ""}
          </p>
          <p className="text-sm italic">
            {competiteur2 ? competiteur2.club_competiteur : ""}
          </p>
        </div>
      </div>
    );
  };

  // Fonction pour afficher les tableaux
  const renderTableau = (tableau, tableauName) => {
    return (
      <div>
        <h3 className="text-lg font-bold mb-4">Tableau {tableauName}</h3>
        {tableau.map((competiteur, index) => {
          if (index % 2 === 0) {
            const competiteur1 = tableau[index];
            const competiteur2 = tableau[index + 1] || null;
            return (
              <div key={index}>
                {renderCombatCard(competiteur1, competiteur2)}
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  };

  // Fonction pour afficher les quarts de finale
  const renderQuartsFinale = () => {
    const quartFinale = eliminationTable.quart_finale.tableau.map((competiteur) => {
      if (typeof competiteur === "string") {
        return "Inconnu(e)";
      }
      return competiteur.nom_competiteur;
    });

    return renderTableau(quartFinale, "Quarts de finale");
  };

  if (loading) {
    return <p>Chargement des données...</p>;
  }

  return (
    <div className="mt-8">
      <h2 className="text-xl font-bold mb-4">Tableau Double Repêchage</h2>

      {/* Affichage des tableaux A et B */}
      {renderTableau(eliminationTable.tour1.tableauA, "A")}
      {renderTableau(eliminationTable.tour1.tableauB, "B")}
      {renderTableau(eliminationTable.tour1.tableauC, "C")}
      {renderTableau(eliminationTable.tour1.tableauD, "D")}

      {/* Affichage des quarts de finale */}
      {renderQuartsFinale()}

      {/* Modal pour enregistrer un nouveau combat */}
      {isModalOpen && selectedCombat && (
        <CombatTableauModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          competiteur1={selectedCombat.competiteur1}
          competiteur2={selectedCombat.competiteur2}
          onSubmit={handleCombatSubmit}
          initialData={null}
          distributionId={distributionId}
          position1={positions.position1}
          position2={positions.position2}
        />
      )}

      {/* Modal pour éditer un combat existant */}
      {isEditModalOpen && selectedCombat && (
        <EditCombatTableauModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          competiteur1={selectedCombat.competiteur1}
          competiteur2={selectedCombat.competiteur2}
          initialData={selectedCombat.combatData}
          distributionId={distributionId}
          position1={positions.position1}
          position2={positions.position2}
          refreshTableau={fetchCombats}
        />
      )}
    </div>
  );
};

export default RepartitionTableauDoubleRepechage;
