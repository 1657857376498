import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/distributions`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer toutes les distributions
export const getAllDistributions = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allDistributions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement des distributions:", error);
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour récupérer une distribution avec son id
export const getDistributionById = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/distribution/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement de la distribution:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour afficher les distributions d'une compétition par son id et sur le tapis avec son id
export const getCompetitionTapisDistributions = async (
  competitionId,
  tapisId
) => {
  try {
    const response = await axios.get(
      `${apiUrl}/competition/${competitionId}/tapis/${tapisId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200)
      throw new Error("Failed to fetch competition tapis distributions");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors du chargement des distributions de la compétition sur le tapis:",
      error
    );
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour afficher les distributions d'une compétition avec son id
export const getCompetitionDistributions = async (competitionId) => {
  try {
    const response = await axios.get(`${apiUrl}/competition/${competitionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error("Failed to fetch competition distributions");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors du chargement des distributions de la compétition:",
      error
    );
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// POST
// Fonction pour ajouter une distribution
export const addDistribution = async (newDistribution) => {
  try {
    // Envoie les compétiteurs en tant que tableau, pas de conversion en JSON ici
    const response = await axios.post(
      `${apiUrl}/addDistribution`,
      newDistribution,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Assurez-vous que le token est valide et défini
        },
      }
    );

    if (response.status !== 201) throw new Error("Failed to add distribution");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de l'ajout de la distribution:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// PUT
// Fonction pour mettre à jour une distribution
export const updateDistribution = async (updatedDistribution) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateDistribution/${updatedDistribution.id}`,
      updatedDistribution,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200)
      throw new Error("Failed to update distribution");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la distribution:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// DELETE
// Fonction pour supprimer une distribution avec son id
export const deleteDistribution = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteDistribution/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error("Failed to delete distribution");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la suppression de la distribution:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};
