import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/competiteurs`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer tous les compétiteurs
export const getAllCompetiteurs = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allCompetiteurs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement des compétiteurs:", error);
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour récupérer un compétiteur avec son id
export const getCompetiteurById = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/competiteur/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement du compétiteur:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour afficher les compétiteurs d'une compétition avec son id
export const getCompetitionCompetiteurs = async (competitionId) => {
  try {
    const response = await axios.get(`${apiUrl}/competition/${competitionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error("Failed to fetch competition competiteurs");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors du chargement des compétiteurs de la compétition:",
      error
    );
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// POST
// Fonction pour ajouter un compétiteur
export const addCompetiteur = async (newCompetiteur) => {
  try {
    const response = await axios.post(
      `${apiUrl}/addCompetiteur`,
      newCompetiteur,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 201) throw new Error("Failed to add competiteur");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de l'ajout du compétiteur:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// PUT
// Fonction pour mettre à jour un compétiteur
export const updateCompetiteur = async (id, updatedCompetiteur) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateCompetiteur/${id}`,
      updatedCompetiteur,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200)
      throw new Error("Failed to update competiteur");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la mise à jour du compétiteur:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour toggle le statut is_present d'un compétiteur
export const toggleCompetiteurPresence = async (id, isPresent) => {
  try {
    const response = await axios.put(
      `${apiUrl}/toggleIsPresent/${id}`,
      { is_present: isPresent },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200)
      throw new Error("Failed to toggle competiteur presence");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors du toggle de la présence du compétiteur:",
      error
    );
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour changer le poids d'un compétiteur par son id
export const changeCompetiteurPoids = async (id, poids_competiteur) => {
  try {
    const response = await axios.put(
      `${apiUrl}/changePoids/${id}`,
      { poids_competiteur }, // Utilise la clé correcte attendue par le backend
      {
        headers: {
          Authorization: `Bearer ${token}`, // S'assure que le token est valide
        },
      }
    );
    if (response.status !== 200)
      throw new Error("Failed to change competiteur poids");
    return response.data; // Renvoie les données en cas de succès
  } catch (error) {
    console.error("Erreur lors du changement de poids du compétiteur:", error);
    return null; // Renvoie null en cas d'échec
  }
};

/// DELETE
// Fonction pour supprimer un compétiteur
export const deleteCompetiteur = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteCompetiteur/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error("Failed to delete competiteur");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la suppression du compétiteur:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};
