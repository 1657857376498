import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaUsers, FaCalendarAlt, FaEnvelope, FaMoneyBillWave, FaUserCheck,
  FaChartBar, FaAddressCard, FaCartPlus, FaRegCreditCard, FaUserCircle,
  FaFileSignature, FaRegNewspaper, FaImage, FaComment, FaHourglassHalf, FaCalculator, FaShoppingBag, FaBook 
} from "react-icons/fa";
import { IoMdContacts, IoMdSettings } from "react-icons/io";
import { GiBlackBelt, GiKimono, GiPodiumWinner, GiPodium, GiGong } from "react-icons/gi";
import { MdDashboard, MdTimer, MdOutlineInventory } from "react-icons/md";
import { PiTranslateFill } from "react-icons/pi";
import { LuListTodo } from "react-icons/lu";
import { TbRelationOneToManyFilled } from "react-icons/tb";

// Association des titres de liens et des icônes
const linkIcon = {
  "Arbitrage": <GiGong />,
  "Compétitions": <GiPodiumWinner />,
  "Listing Utilisateurs": <FaUsers />,
  "Compétitions Admin": <GiPodium />,
  "Paramètres": <IoMdSettings />,
  "Règlement CS": <FaBook />,
  "Quiz": <LuListTodo />,
  "Tableau de Score": <TbRelationOneToManyFilled />,
};

// Définition des sections en fonction des rôles
const sections = {
  guest: {
    label: "Public",
    links: ["Arbitrage", "Règlement CS", "Quiz", "Tableau de Score"]
  },
  2: {
    label: "Utilisateur",
    links: ["Arbitrage"]
  },
  3: {
    label: "Client",
    links: ["Arbitrage", "Compétitions", "Suivi Combats"]
  },
  1: {
    label: "Administrateur",
    links: ["Listing Utilisateurs", "Compétitions Admin", "Paramètres"]
  },
};

const MegaMenu = ({ role_id, settings, onClose }) => {
  // Ordre des sections en fonction du rôle
  const orderedSections =
    role_id === 1
      ? [sections.guest, sections[2], sections[3], sections[1]] // Admin voit tout
      : role_id === 3
      ? [sections.guest, sections[2], sections[3]] // Client voit public + utilisateur + client
      : role_id === 2
      ? [sections.guest, sections[2]] // Utilisateur voit public + utilisateur
      : [sections.guest]; // Visiteur non connecté voit uniquement public

  const getCardBackgroundColor = (sectionLabel) => {
    if (sectionLabel === "Administrateur") return "bg-green-100 hover:bg-green-200";
    if (sectionLabel === "Client") return "bg-blue-100 hover:bg-blue-200";
    if (sectionLabel === "Utilisateur") return "bg-gray-100 hover:bg-gray-200";
    return "bg-white hover:bg-blue-100"; // Par défaut pour le public
  };

  const shouldDisplayLink = (link) => {
    const keyMap = {
      "Boutique": "display_boutique",
      "Chat": "display_chat",
      "Actualités": "display_news",
      "Galerie Photos": "display_galerie",
      "Lexique": "display_lexique",
      "Techniques Judo": "display_techniques",
      "Arbitrage Judo": "display_arbitrage",
      "Appel Présence": "display_appels",
      "ToDoList": "display_todolist",
      "Coachings": "display_coaching",
      "Heures Enseignement": "display_heures_enseignants",
      "Inventaire": "display_inventaire",
      "Chat Page Admin": "display_chat_admin",
      "Message Admin": "display_messages_admin",
    };

    const key = keyMap[link];
    return key ? settings[key] : true;
  };

  return (
    <div className="max-w-[1280px] mx-auto bg-base-200 dark:bg-slate-600 font-montserrat overflow-hidden relative">
      <div className="overflow-y-auto max-h-[70vh]">
        {/* Bouton de fermeture */}
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-4 mr-6 text-white bg-primary hover:bg-primary-dark rounded-full focus:outline-none h-8 w-8 text-xl font-semibold"
          style={{ zIndex: 10 }}
        >
          X
        </button>

        {orderedSections.map((section, idx) => (
          <div key={idx} className="p-6">
            <div className="font-montserrat text-primary dark:text-white shadow-sm text-lg font-semibold mb-4">
              {section.label}
            </div>
            <ul className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {section.links.filter(shouldDisplayLink).map((link, linkIdx) => (
                <li key={linkIdx} className="text-center">
                  <NavLink
                    to={`/${link.toLowerCase().replace(/[\s'ç]/g, '-').normalize("NFD").replace(/[\u0300-\u036f]/g, '')}`}
                    className={`dark:bg-gray-800 block relative overflow-hidden rounded-lg p-3 shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 ${getCardBackgroundColor(section.label)}`}
                    onClick={onClose}
                  >
                    <div className="flex flex-col items-center justify-center">
                      <div className="text-3xl text-primary my-3">{linkIcon[link]}</div>
                      <span className="block text-sm font-semibold text-gray-800 dark:text-white">{link}</span>
                    </div>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MegaMenu;
