import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getCompetitionCombats } from "./../../services/combatsServices";
import { getAllDistributions } from "./../../services/distributionsServices"; // Pour récupérer les distributions
import Button from "./../../components/Button/Button";
import { ToastContainer, toast } from "react-toastify";
import useScrollToTop from "../../hooks/useScrollToTop";
import ScrollIndicator from "./../../hooks/ScrollIndicator";
import { useUser } from "./../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../utils/roleHelper";

const SuiviCombatsPage = ({ competitionId }) => {
  useScrollToTop();
  const { id } = useParams();
  const { user } = useUser();
  const navigate = useNavigate();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;
  
  const [combats, setCombats] = useState([]);
  const [competition, setCompetition] = useState(null);
  const [distributions, setDistributions] = useState([]);
  const [selectedDistribution, setSelectedDistribution] = useState("all");
  const [loading, setLoading] = useState(true);

  // Fonction pour récupérer les combats d'une compétition
  const fetchCombats = async () => {
    try {
      const fetchedCombats = await getCompetitionCombats(competitionId);
      setCombats(fetchedCombats);
    } catch (error) {
      toast.error("Erreur lors du chargement des combats.");
    }
  };

  // Fonction pour récupérer toutes les distributions
  const fetchDistributions = async () => {
    try {
      const fetchedDistributions = await getAllDistributions(competitionId);
      setDistributions(fetchedDistributions);
    } catch (error) {
      toast.error("Erreur lors du chargement des distributions.");
    }
  };

  useEffect(() => {
    fetchCombats();
    fetchDistributions();
    setLoading(false);
  }, [competitionId]);

  // Filtrer les combats en fonction de la distribution sélectionnée
  const filteredCombats = combats.filter((combat) =>
    selectedDistribution === "all"
      ? true
      : combat.distribution_id === parseInt(selectedDistribution)
  );

  if (loading) {
    return <p>Chargement des données...</p>;
  }

  return (
    <div className="flex flex-col w-full mx-auto p-4 mt-[130px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        Détails de la Compétition
      </h2>
      
      {/* Vérifier si l'objet competition est défini avant d'accéder à nom_competition */}
      <div className="bg-white dark:bg-gray-800 shadow-md p-4 rounded-lg mx-auto">
        <h3 className="text-lg font-bold mb-4 font-lato">
          {competition ? competition.nom_competition : "Chargement..."}
        </h3>
      </div>

      {/* Filtre par distribution */}
      <div className="mb-4">
        <label htmlFor="distribution-filter" className="mr-2 font-bold">
          Filtrer par Distribution :
        </label>
        <select
          id="distribution-filter"
          value={selectedDistribution}
          onChange={(e) => setSelectedDistribution(e.target.value)}
          className="border p-2"
        >
          <option value="all">Toutes les Distributions</option>
          {distributions.map((distribution) => (
            <option key={distribution.id} value={distribution.id}>
              Distribution {distribution.nom}
            </option>
          ))}
        </select>
      </div>

      {/* Tableau des combats */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full bg-white shadow-md text-xs font-lato">
          <thead>
            <tr className="bg-gray-800 text-white">
              <th className="px-4 py-2">ID Combat</th>
              <th className="px-4 py-2">Compétiteur 1</th>
              <th className="px-4 py-2">Compétiteur 2</th>
              <th className="px-4 py-2">Résultat Compétiteur 1</th>
              <th className="px-4 py-2">Résultat Compétiteur 2</th>
              <th className="px-4 py-2">Points Compétiteur 1</th>
              <th className="px-4 py-2">Points Compétiteur 2</th>
              <th className="px-4 py-2">Distribution</th>
            </tr>
          </thead>
          <tbody>
            {filteredCombats.length > 0 ? (
              filteredCombats.map((combat) => (
                <tr key={combat.id} className="border px-4 py-2">
                  <td className="border px-4 py-2 text-center">{combat.id}</td>
                  <td className="border px-4 py-2 text-center">
                    {combat.competiteur_id1}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {combat.competiteur_id2}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {combat.resultat_competiteur1}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {combat.resultat_competiteur2}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {combat.points_competiteur1}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {combat.points_competiteur2}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    Distribution {combat.distribution_id}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center py-4">
                  Aucun combat trouvé.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <ToastContainer />
    </div>
  );
};

export default SuiviCombatsPage;
