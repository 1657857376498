export const calculateClassementPoule = (competiteurs, combats) => {
  const classementMap = {};

  // Initialiser les compétiteurs dans le classement
  competiteurs.forEach((competiteur) => {
    classementMap[competiteur.id] = {
      nom_competiteur: competiteur.nom_competiteur,
      victoires: 0,
      points: 0,
      disqualifie: false,
      exAequo: false,
    };
  });

  // Calcul des victoires, des points et gestion des disqualifications
  combats.forEach((combat) => {
    const {
      competiteur_id1,
      competiteur_id2,
      resultat_competiteur1,
      resultat_competiteur2,
      points_competiteur1,
      points_competiteur2,
    } = combat;

    if (resultat_competiteur1 === "H")
      classementMap[competiteur_id1].disqualifie = true;
    if (resultat_competiteur2 === "H")
      classementMap[competiteur_id2].disqualifie = true;

    if (!classementMap[competiteur_id1].disqualifie) {
      classementMap[competiteur_id1].points += points_competiteur1;
      if (resultat_competiteur1 === "V")
        classementMap[competiteur_id1].victoires += 1;
    }

    if (!classementMap[competiteur_id2].disqualifie) {
      classementMap[competiteur_id2].points += points_competiteur2;
      if (resultat_competiteur2 === "V")
        classementMap[competiteur_id2].victoires += 1;
    }
  });

  // Trier les compétiteurs par victoires et points
  let sortedClassement = Object.values(classementMap)
    .filter((competiteur) => !competiteur.disqualifie)
    .sort((a, b) => b.victoires - a.victoires || b.points - a.points);

  let currentPosition = 1; // Position actuelle dans le classement
  let previousVictoires = null;
  let previousPoints = null;

  sortedClassement.forEach((competiteur, index) => {
    // Si c'est une égalité (mêmes victoires et points que le précédent)
    if (
      previousVictoires !== null &&
      competiteur.victoires === previousVictoires &&
      competiteur.points === previousPoints
    ) {
      competiteur.position = currentPosition;
      competiteur.exAequo = true;
      sortedClassement[index - 1].exAequo = true;
    } else {
      // Si ce n'est pas une égalité, on met à jour la position
      currentPosition = index + 1;
      competiteur.position = currentPosition;
      competiteur.exAequo = false;
    }

    // Mémoriser les victoires et points du compétiteur actuel
    previousVictoires = competiteur.victoires;
    previousPoints = competiteur.points;
  });

  // Ajouter les disqualifiés à la fin du classement
  const disqualified = Object.values(classementMap).filter(
    (competiteur) => competiteur.disqualifie
  );

  return [...sortedClassement, ...disqualified];
};
