import React, { useState, useEffect } from "react";
import CombatTableauModal from "./../CombatTableauModal";
import EditCombatTableauModal from "./../EditCombatTableauModal";
import { getDistributionCombats } from "../../services/combatsServices";
import { generateEliminationTable } from "../../utils/generateEliminationTable";

const RepartitionTableauSansRepechage = ({ competiteurs, distributionId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedCombat, setSelectedCombat] = useState(null);
  const [positions, setPositions] = useState({ position1: null, position2: null });
  const [combats, setCombats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eliminationTable, setEliminationTable] = useState([]);

  // Fonction pour récupérer les combats de la distribution
  const fetchCombats = async () => {
    setLoading(true);
    try {
      const fetchedCombats = await getDistributionCombats(distributionId);
      setCombats(fetchedCombats);
      setEliminationTable(generateEliminationTable(competiteurs));
    } catch (error) {
      console.error("Erreur lors de la récupération des combats :", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
  fetchCombats();
  const { tableauA, tableauB } = generateEliminationTable(competiteurs);
  setEliminationTable({ tableauA, tableauB });
}, [distributionId]);

  // Fonction pour rafraîchir le tableau des combats
  const refreshTableau = async () => {
    await fetchCombats();
  };

  // Fonction pour ouvrir la modale d'ajout d'un combat
  const openModal = (competiteur1, competiteur2, position1, position2) => {
    setSelectedCombat({
      competiteur1,
      competiteur2,
    });
    setPositions({ position1, position2 });
    setIsModalOpen(true);
  };

  // Fonction pour ouvrir la modale d'édition d'un combat existant
  const openEditModal = (combatData, competiteur1, competiteur2, position1, position2) => {
    setSelectedCombat({
      competiteur1,
      competiteur2,
      combatData,
    });
    setPositions({ position1, position2 });
    setIsEditModalOpen(true);
  };

  // Fonction appelée à la soumission d'un combat
  const handleCombatSubmit = async (combatData) => {
    await fetchCombats(); // Rafraîchir les combats après soumission
    setIsModalOpen(false); // Fermer la modale après la mise à jour des combats
  };

  // Fonction pour obtenir le résultat d'un combat
  const getCombatResult = (competiteur1Id, competiteur2Id) => {
    return combats.find(
      (combat) =>
        (combat.competiteur_id1 === competiteur1Id && combat.competiteur_id2 === competiteur2Id) ||
        (combat.competiteur_id1 === competiteur2Id && combat.competiteur_id2 === competiteur1Id)
    );
  };

  // Définir la couleur de fond en fonction du résultat
  const getResultColor = (result) => {
    switch (result) {
      case "V":
        return "bg-green-200";
      case "D":
        return "bg-red-200";
      case "N":
        return "bg-yellow-200";
      case "F":
        return "bg-gray-200";
      case "H":
        return "bg-red-600 text-white";
      default:
        return "";
    }
  };




   // Affichage du résultat du combat
  const renderCombatResult = (competiteur1, competiteur2) => {
    const combat = getCombatResult(competiteur1.id, competiteur2?.id);

    if (!combat) return null;

    const resultCompetiteur1 = combat.resultat_competiteur1;
    const resultCompetiteur2 = combat.resultat_competiteur2;

    return (
      <div className="flex justify-center">
        <span className={`${getResultColor(resultCompetiteur1)} p-1 rounded mr-2`}>
          {resultCompetiteur1} ({combat.points_competiteur1})
        </span>{" "}
        vs{" "}
        <span className={`${getResultColor(resultCompetiteur2)} p-1 rounded ml-2`}>
          {resultCompetiteur2} ({combat.points_competiteur2})
        </span>
      </div>
    );
  };

    // Créer des paires de compétiteurs en fonction des tableaux A et B avec gestion des sauts de tour
  const tableauA = [];
  const tableauB = [];
  let soloCompetiteur = null;

  let counter = 0;

  for (let i = 0; i < competiteurs.length; i += 2) {
    if (counter % 4 === 0 || counter % 4 === 2) {
      // Répartir les compétiteurs de manière alternée dans le tableau A
      if (i + 1 < competiteurs.length) {
        tableauA.push({
          competiteur1: competiteurs[i],
          competiteur2: competiteurs[i + 1],
          position1: i + 1,
          position2: i + 2,
        });
      } else {
        tableauA.push({
          competiteur1: competiteurs[i],
          competiteur2: null, // Competiteur solo
          position1: i + 1,
          position2: null,
        });
      }
    } else {
      // Répartir les compétiteurs dans le tableau B
      if (i + 1 < competiteurs.length) {
        tableauB.push({
          competiteur1: competiteurs[i],
          competiteur2: competiteurs[i + 1],
          position1: i + 1,
          position2: i + 2,
        });
      } else {
        tableauB.push({
          competiteur1: competiteurs[i],
          competiteur2: null, // Competiteur solo
          position1: i + 1,
          position2: null,
        });
      }
    }
    counter++;
  }

  // Fonction pour afficher les combats
  const renderTableau = (tableau, tableauName) => {
    return (
      <div>
        <h3 className="text-lg font-bold mb-4">Tableau {tableauName}</h3>
        {tableau.map((pair, index) => {
          const combatResult = pair.competiteur2
            ? getCombatResult(pair.competiteur1.id, pair.competiteur2.id)
            : null;

          const resultCompetiteur1 = combatResult ? combatResult.resultat_competiteur1 : null;
          const resultCompetiteur2 = combatResult ? combatResult.resultat_competiteur2 : null;

          return (
            <div
              key={index}
              className={`bg-white dark:bg-gray-800 shadow-md p-4 rounded-lg mb-4 ${combatResult ? "bg-gray-100" : ""}`}
            >
              <div className="flex flex-col md:flex-row justify-between items-center">
                <div
                  className={`p-4 rounded-lg border border-gray-300 w-full md:w-1/3 text-center ${
                    resultCompetiteur1 ? getResultColor(resultCompetiteur1) : ""
                  }`}
                >
                  <p className="text-lg font-bold">
                    {pair.competiteur1.nom_competiteur} {pair.competiteur1.prenom_competiteur}
                  </p>
                  <p className="text-sm italic">{pair.competiteur1.club_competiteur}</p>
                </div>

                <div className="my-4 md:my-0 md:mx-4 flex items-center justify-center">
                  {combatResult ? (
                    <button
                      className="bg-green-500 text-white px-4 py-2 rounded"
                      onClick={() =>
                        openEditModal(
                          combatResult,
                          pair.competiteur1,
                          pair.competiteur2,
                          pair.position1,
                          pair.position2
                        )
                      }
                    >
                      Éditer Résultats
                    </button>
                  ) : (
                    pair.competiteur2 && (
                      <button
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                        onClick={() =>
                          openModal(pair.competiteur1, pair.competiteur2, pair.position1, pair.position2)
                        }
                      >
                        Enregistrer Résultats
                      </button>
                    )
                  )}
                </div>

                {pair.competiteur2 ? (
                  <div
                    className={`p-4 rounded-lg border border-gray-300 w-full md:w-1/3 text-center ${
                      resultCompetiteur2 ? getResultColor(resultCompetiteur2) : ""
                    }`}
                  >
                    <p className="text-lg font-bold">
                      {pair.competiteur2.nom_competiteur} {pair.competiteur2.prenom_competiteur}
                    </p>
                    <p className="text-sm italic">{pair.competiteur2.club_competiteur}</p>
                  </div>
                ) : (
                  <div className="text-center p-4 w-full md:w-1/3">
                    <p className="text-lg font-bold">Exempt</p>
                    <p className="text-sm italic">(saute un tour)</p>
                  </div>
                )}
              </div>

              {/* Affichage du résultat du combat */}
              {renderCombatResult(pair.competiteur1, pair.competiteur2)}
            </div>
          );
        })}
      </div>
    );
  };

  if (loading) {
    return <p>Chargement des données...</p>;
  }

  return (
    <div className="mt-8">
      <h2 className="text-xl font-bold mb-4">Tableau Sans Repêchage</h2>
      <button
        onClick={refreshTableau}
        className="ml-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-4"
      >
        Recharger
      </button>

      {/* Affichage des tableaux A et B */}
      {renderTableau(tableauA, "A")}
      {renderTableau(tableauB, "B")}

      {/* Modal pour enregistrer un nouveau combat */}
      {isModalOpen && selectedCombat && (
        <CombatTableauModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          competiteur1={selectedCombat.competiteur1}
          competiteur2={
            selectedCombat.competiteur2 || {
              nom_competiteur: "Exempt",
              prenom_competiteur: "",
              club_competiteur: "",
            }
          }
          onSubmit={handleCombatSubmit}
          initialData={null}
          distributionId={distributionId}
          position1={positions.position1}
          position2={positions.position2 || "Exempt"}
        />
      )}

      {/* Modal pour éditer un combat existant */}
      {isEditModalOpen && selectedCombat && (
        <EditCombatTableauModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          competiteur1={selectedCombat.competiteur1}
          competiteur2={selectedCombat.competiteur2}
          initialData={selectedCombat.combatData}
          distributionId={distributionId}
          position1={positions.position1}
          position2={positions.position2}
          refreshTableau={refreshTableau}
        />
      )}
    </div>
  );
};

export default RepartitionTableauSansRepechage;
