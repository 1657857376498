import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoIosMenu } from "react-icons/io";
import "./LinkNav.css";
import MegaMenu from "./MegaMenu";
import LogoSection from "./LogoSection";
import Modal from "../../../components/Modal"; // Composant pour gérer les modales
import { getSettings } from "../../../services/settingsServices";

const LinkNav = ({ user }) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [settings, setSettings] = useState({
    adresse_club: "",
    tel_club: "",
    mail_club: "",
    facebook_club: "",
    instagram_club: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      const data = await getSettings();
      if (data.length > 0) setSettings(data[0]);
    };
    fetchSettings();
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
    if (showMegaMenu) setShowMegaMenu(false);
  };

  const toggleMegaMenu = () => {
    setShowMegaMenu(!showMegaMenu);
    if (showDropdown) setShowDropdown(false);
  };

  const handleNavigate = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/");
    }
    toggleDropdown();
  };

  return (
    <div className="flex bg-white bg-opacity-85 text-black py-2 shadow-md items-center justify-between w-full">
      {/* Logo */}
      <div className="flex items-center ml-2">
        <NavLink to="/">
          <LogoSection />
        </NavLink>
      </div>

      {/* Menu principal visible en desktop */}
      <div className="hidden lg:flex space-x-6 grow justify-center">
        {["accueil", "description", "tarifs", "contact"].map((section) => (
          <NavLink
            key={section}
            to="/"
            state={{ scrollTo: section }}
            onClick={() => handleNavigate(section)}
            className="uppercase font-medium hover:text-orange-600 text-black"
          >
            {section.charAt(0).toUpperCase() + section.slice(1)}
          </NavLink>
        ))}
      </div>

      {/* Bouton Application et menu hamburger */}
      <div className="flex items-center space-x-4 mr-2">
        {/* Bouton Application accessible à tous */}
        <button
          onClick={toggleMegaMenu}
          aria-label="Application"
          title="Application"
          className="bg-primary text-white px-4 py-2 rounded-3xl shadow-sm hover:bg-white hover:text-primary border border-primary"
        >
          Application
        </button>

        {/* Menu hamburger pour mobile */}
        <button
          onClick={toggleDropdown}
          aria-label="Menu"
          title="Menu"
          className="lg:hidden bg-primary text-white px-4 py-2 rounded-3xl shadow-sm hover:bg-white hover:text-primary border border-primary"
        >
          <IoIosMenu className="text-xl" />
        </button>
      </div>

      {/* Menu déroulant pour mobile */}
      {showDropdown && (
        <div className="dropdown-content shadow absolute top-full right-0 py-2 z-20 w-full bg-white/90 lg:hidden">
          <div className="flex flex-col items-center">
            {["accueil", "description", "tarifs", "contact"].map((section) => (
              <NavLink
                key={section}
                to="/"
                state={{ scrollTo: section }}
                onClick={() => handleNavigate(section)}
                className="block w-full px-4 py-3 text-xl hover:bg-primary hover:text-white text-black text-center"
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </NavLink>
            ))}
          </div>
        </div>
      )}

      {/* Mégamenu accessible à tous */}
      {showMegaMenu && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50"
          onClick={toggleMegaMenu}
        >
          <div className="absolute top-32 right-0 left-0 mx-auto w-full max-w-[1280px] px-4 shadow-xl">
            <MegaMenu
              role_id={user?.role_id || "guest"} // Si non connecté, rôle "guest"
              onClose={toggleMegaMenu}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LinkNav;
