import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from './../../components/Button/Button';
import { updateCompetiteur, getCompetiteurById } from './../../services/competiteursServices';
import { toast } from 'react-toastify';

const EditCompetiteurModal = ({ isOpen, onClose, competiteur, refreshList }) => {
    const [competiteurData, setCompetiteurData] = useState({
        nom_competiteur: '',
        prenom_competiteur: '',
        ddn_competiteur: '',
        club_competiteur: '',
        poids_competiteur: '',
        genre_competiteur: '',
    });

    useEffect(() => {
        if (isOpen && competiteur) {
            setCompetiteurData({
                nom_competiteur: competiteur.nom_competiteur,
                prenom_competiteur: competiteur.prenom_competiteur,
                ddn_competiteur: competiteur.ddn_competiteur,
                club_competiteur: competiteur.club_competiteur,
                poids_competiteur: competiteur.poids_competiteur,
                genre_competiteur: competiteur.genre_competiteur,
            });
        }
    }, [isOpen, competiteur]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCompetiteurData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateCompetiteur(competiteur.id, competiteurData);
            toast.success("Compétiteur mis à jour avec succès");
            refreshList();
            onClose();
        } catch (error) {
            toast.error("Erreur lors de la mise à jour du compétiteur");
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
                <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Éditer un Compétiteur</h3>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-sm font-bold mb-2">Nom:</label>
                        <input
                            className="input input-bordered w-full"
                            type="text"
                            name="nom_competiteur"
                            value={competiteurData.nom_competiteur}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Prénom:</label>
                        <input
                            className="input input-bordered w-full"
                            type="text"
                            name="prenom_competiteur"
                            value={competiteurData.prenom_competiteur}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Date de Naissance:</label>
                        <input
                            className="input input-bordered w-full"
                            type="date"
                            name="ddn_competiteur"
                            value={competiteurData.ddn_competiteur}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Club:</label>
                        <input
                            className="input input-bordered w-full"
                            type="text"
                            name="club_competiteur"
                            value={competiteurData.club_competiteur}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Poids (kg):</label>
                        <input
                            className="input input-bordered w-full"
                            type="number"
                            name="poids_competiteur"
                            value={competiteurData.poids_competiteur}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Genre:</label>
                        <select
                            className="select select-bordered w-full dark:text-black"
                            name="genre_competiteur"
                            value={competiteurData.genre_competiteur}
                            onChange={handleChange}
                            required
                        >
                            <option value="M">Masculin</option>
                            <option value="F">Féminin</option>
                        </select>
                    </div>
                    <div className="flex justify-center space-x-2 mt-4">
                        <Button text="Mettre à jour" icon={FaSave} type="submit" className="btn-primary gap-2" />
                        <Button text="Fermer" icon={FaTimes} onClick={onClose} className="btn-ghost gap-2" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditCompetiteurModal;
