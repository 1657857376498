import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/competitions`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer toutes les compétitions
export const getAllCompetitions = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allCompetitions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement des compétitions:", error);
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour récupérer une compétition avec son id
export const getCompetitionById = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/competition/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  }
  catch (error) {
    console.error("Erreur lors du chargement de la compétition:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
}

// Fonction pour afficher les compétitions d'un utilisateur avec son id
export const getUserCompetitions = async (userId) => {
  try {
    const response = await axios.get(`${apiUrl}/client/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch user competitions");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement des compétitions de l'utilisateur:", error);
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// POST
// Fonction pour ajouter une compétition
export const addCompetition = async (newCompetition) => {
  try {
    const response = await axios.post(
      `${apiUrl}/addCompetition`,
      newCompetition,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 201)
      throw new Error("Failed to add new competition");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de l'ajout d'une compétition:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// PUT
// Fonction pour modifier une compétition avec son id
export const updateCompetition = async (id, updatedCompetition) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateCompetition/${id}`,
      updatedCompetition,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to update competition");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la modification d'une compétition:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour modifier l'activation d'une compétition par son id
export const updateCompetitionActivation = async (id, isActive) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateCompetitionActive/${id}`,
      { isActive },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to update competition activation");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la modification de l'activation d'une compétition:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// DELETE
// Fonction pour supprimer une compétition avec son id
export const deleteCompetition = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteCompetition/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to delete competition");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la suppression d'une compétition:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};




