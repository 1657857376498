import React, { useState } from "react";
import { useUser } from "./../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../utils/roleHelper";
import useScrollToTop from "./../../hooks/useScrollToTop";
import ScrollIndicator from "./../../hooks/ScrollIndicator";
import { FaChevronUp, FaChevronDown, FaTimes } from "react-icons/fa";

const Dropdown = ({ title, children, isOpen, toggle }) => (
  <div className="bg-blue-100 rounded-lg shadow mb-4 p-4 max-w-[800px] mx-auto w-full">
    <button
      onClick={toggle}
      className="w-full flex justify-between items-center text-left text-lg font-semibold dark:text-gray-800"
    >
      {title}
      {isOpen ? <FaChevronUp /> : <FaChevronDown />}
    </button>
    {isOpen && <div className="mt-2">{children}</div>}
  </div>
);

const ReglementCS = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);
  const [isOpen9, setIsOpen9] = useState(false);
  const [isOpen10, setIsOpen10] = useState(false);
  const [isOpen11, setIsOpen11] = useState(false);
  const [isOpen12, setIsOpen12] = useState(false);
  const [isOpen13, setIsOpen13] = useState(false);
  const [isOpen14, setIsOpen14] = useState(false);
  const [isOpen15, setIsOpen15] = useState(false);
  const [isOpen16, setIsOpen16] = useState(false);

  const toggleDropdown1 = () => setIsOpen1(!isOpen1);
  const toggleDropdown2 = () => setIsOpen2(!isOpen2);
  const toggleDropdown3 = () => setIsOpen3(!isOpen3);
  const toggleDropdown4 = () => setIsOpen4(!isOpen4);
  const toggleDropdown5 = () => setIsOpen5(!isOpen5);
  const toggleDropdown6 = () => setIsOpen6(!isOpen6);
  const toggleDropdown7 = () => setIsOpen7(!isOpen7);
  const toggleDropdown8 = () => setIsOpen8(!isOpen8);
  const toggleDropdown9 = () => setIsOpen9(!isOpen9);
  const toggleDropdown10 = () => setIsOpen10(!isOpen10);
  const toggleDropdown11 = () => setIsOpen11(!isOpen11);
  const toggleDropdown12 = () => setIsOpen12(!isOpen12);
  const toggleDropdown13 = () => setIsOpen13(!isOpen13);
  const toggleDropdown14 = () => setIsOpen14(!isOpen14);
  const toggleDropdown15 = () => setIsOpen15(!isOpen15);
  const toggleDropdown16 = () => setIsOpen16(!isOpen16);

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Règlement des Commissaires Sportifs
      </h2>
      <div className="mt-4 space-y-8 mx-auto p-4 max-w-[1280px]">

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 Rôle du Commissaire Sportif</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4">
              <p>🔸Suivi des combats</p>
              <p>🔸Enregistrement des scores</p>
              <p>🔸Gestion du temps des combats</p>
              <p>🔸Annonce des Résultats</p>
              <p>🔸Gestion de la pesée</p>
            </div>
            <div className="md:w-1/2 p-4">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1720732018/images/arbitrage/arbitrage_judo_05_yce4cw.webp" alt="Rôle du Commissaire Sportif" className="rounded-lg shadow-md" />
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 Quelques règles</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4 order-2 md:order-1">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1720757031/images/arbitrage/arbitrage_judo_06_h2yqp4.jpg" alt="Gestes de l'arbitre" className="rounded-lg shadow-md" />
            </div>
            <div className="md:w-1/2 p-4 order-1 md:order-2">
              <Dropdown title="Ceinture rouge, Kimono bleu ?" isOpen={isOpen1} toggle={toggleDropdown1}>
                <p>Appel des judokas : en France, le premier judoka appelé porte la ceinture rouge. Contrairement à la FIJ, le premier appelé porte un kimono blanc, le second un kimono bleu</p>
              </Dropdown>
              <Dropdown title="Coaching" isOpen={isOpen2} toggle={toggleDropdown2}>
                <p>Des Benjamins jusqu’aux Seniors, le coaching se fera
                entre les « Matte » et les « Hajime » de l’arbitre.</p>
              </Dropdown>
              <Dropdown title="Temps d'immobilisation" isOpen={isOpen3} toggle={toggleDropdown3}>
                <p>Temps communs à toutes les tranches d’âge :</p>
                <p>🔸Ippon 20 secondes</p>
                <p>🔸Waza-Ari de 10 à 19 secondes</p>
                <p>🔸Kinza moins de 10 secondes (pour animations uniquement)</p>
              </Dropdown>
              <Dropdown title="Notation des Scores" isOpen={isOpen4} toggle={toggleDropdown4}>
                <p>La notation s’établie désormais à 2 chiffres, suivie du nombre
                de shido, en cohérence avec le logiciel de tirage au sort..</p>
                <p>🔸Exemples : 10 / 00 - 10 / 01.3 - 01.1 / 00.2 - 02.2 / 01 ......</p>
              </Dropdown>
              <Dropdown title="Sanction par Shido" isOpen={isOpen5} toggle={toggleDropdown5}>
                <p>Les shido ne permettent pas de donner la victoire, sauf le 3ème
                qui est disqualificatif ! Un judoka peut recevoir jusqu’à 3 shido pendant un combat.</p>
              </Dropdown>
              <Dropdown title="Disqualification" isOpen={isOpen6} toggle={toggleDropdown6}>
                <p>Il existe 3 cas de disqualification possible lors d’un combat :</p>
                <p>🔸Hansoku make direct, non disqualificatif (faute technique
                grave – le judoka peut reprendre la compétition) = 10 / 00.H</p>
                <p>🔸Hansoku make direct, disqualificatif (faute contraire à l’esprit –
                le judoka ne peut pas reprendre la compétition) = 10 / 00.X</p>
                <p>🔸Hansoku make par cumul de shido = 10 / 00.3</p>
              </Dropdown>
              <Dropdown title="Disqualification" isOpen={isOpen7} toggle={toggleDropdown7}>
                <p>Il existe 3 cas possibles lors d’un combat :</p>
                <p>🔸Forfait (non présentation sur un combat) = 10 / 00.F</p>
                <p>🔸Abandon (le judoka abandonne de lui-même suite à une
                blessure) = 10 / 00.A</p>
                <p>🔸Décision médicale (le médecin ne permet pas la reprise du
                combat au judoka) = 10 / 00.M</p>
              </Dropdown>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 Attribution des points en poule
ou en équipe (pour les classements)</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4 text-base">
              <p>🔸Victoire par waza-ari : 01 / 00 = 1 point</p>
              <p>🔸Victoire par waza-ari awasete ippon : 02 / 00 = 10 points</p>
              <p>🔸Victoire par ippon : 10 / 00 = 10 points</p>
              <p>🔸Victoire par disqualification ou raison médicale
              (10 / 00.3 ou H, X, F, A, M) = 10 points</p>
              <p>🔸Victoire au golden score (avantage décisif) :</p>
              <p className="pl-8">- Par ippon ou waza-ari awasete ippon = 10 points</p>
              <p className="pl-8">- Par disqualification = 10 points</p>
              <p className="pl-8">- Par waza-ari = 1 point</p>
              <p>🔸Victoire par décision (règlement jeunes ou animations) = 0 point</p>
            </div>
            <div className="md:w-1/2 p-4">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1720731797/images/arbitrage/arbitrage_judo_04_o2hiap.webp" alt="Système de notation" className="rounded-lg shadow-md" />
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 Critères de classement pour les compétitions
          individuelles en poule</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4 order-2 md:order-1">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1720731412/images/arbitrage/arbitrage_judo_03_b1ibhn.jpg" alt="Actions interdites" className="rounded-lg shadow-md" />
            </div>
            <div className="md:w-1/2 p-4 order-1 md:order-2 text-base">
              <p>1️⃣ Nombre de victoires individuelles</p>
              <p>2️⃣ Nombre de points marqués</p>
              <p className="pl-8">- Ippon, waza-ari awasete ippon, hansoku make, forfait, blessure,...
              (même dans l’avantage décisif) = 10 points</p>
              <p className="pl-8">- Waza Ari (en victoire ou défaite, pendant le temps nominal ou l’avantage décisif)
              = 1 point</p>
              <p className="pl-8">- Victoire par décision (animations) = 0 point
              (pour les combats se terminant sur le score 01 / 01, aucun point n’attribué au perdant)</p>
              <p>3️⃣ Comparaison directe (si égalité parfaite entre 2 judokas)</p>
              <p>4️⃣ Poule en Avantage décisif (si plus de 2 judokas sont à égalité parfaite)</p>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 Exemples – Classement en poule</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4">
              <Dropdown title="Les temps de combat en fonction des catégories" isOpen={isOpen13} toggle={toggleDropdown13}>
                <p>🔸Poussins, Mini-Poussins : 1m30</p>
                <p>🔸Benjamins : 2m</p>
                <p>🔸Minimes, Cadets-Juniors-Séniors (2D-3D) : 3m</p>
                <p>🔸Cadets (1D), Juniors (1D), Seniors (1D): 4m</p>
                <p>🔸Temps de récupération : 2x la durée d'un combat</p>
              </Dropdown>
              <Dropdown title="Golden Score (prolongation)" isOpen={isOpen14} toggle={toggleDropdown14}>
                <p>Golden Score est une période de prolongation utilisée pour départager les judokas lorsque le temps réglementaire se termine sans qu'un vainqueur soit déterminé. Durant cette phase, le premier judoka à marquer un point (Ippon, Waza-ari, ou par une pénalité Shido attribuée à l'adversaire) remporte le combat. Il n'y a pas de limite de temps pour le Golden Score, et le combat continue jusqu'à ce qu'une action décisive se produise.</p>
              </Dropdown>
            </div>
            <div className="md:w-1/2 p-4">
              <img src="https://res.cloudinary.com/dj0c0zuje/image/upload/v1733517747/poule_001_g1uahu.png" alt="Exemple de poule" className="rounded-lg shadow-md" />
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 Table d'arbitrage & Commissaires Sportifs</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4 order-2 md:order-1">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1720732018/images/arbitrage/arbitrage_judo_05_yce4cw.webp" alt="Équipement de l'arbitre" className="rounded-lg shadow-md" />
            </div>
            <div className="md:w-1/2 p-4 order-1 md:order-2">
              <Dropdown title="Enregistrement des Scores, du temps, annonce des résultats" isOpen={isOpen15} toggle={toggleDropdown15}>
                <p>🔸Les commissaires sportifs enregistrent les points marqués par les judokas (Ippon, Waza-ari) et les pénalités (Shido, Hansoku-make) en temps réel.</p>
                <p>🔸Ils supervisent le chronomètre, assurant que la durée des combats et des immobilisations au sol est respectée, y compris le temps pour le Golden Score.</p>
                <p>🔸Ils assistent l'arbitre en vérifiant et confirmant les décisions prises sur le tapis, en utilisant parfois des outils vidéo pour les vérifications (révision vidéo).</p>
                <p>🔸Après chaque combat, ils annoncent officiellement le vainqueur en fonction des points et pénalités enregistrés.</p>
              </Dropdown>
              <Dropdown title="Importance de la Table de Marquage" isOpen={isOpen16} toggle={toggleDropdown16}>
                <p>La table de marquage assure une gestion équitable et transparente des combats. Les commissaires sportifs y travaillent en étroite collaboration avec les arbitres pour garantir que les règles du judo sont appliquées correctement et que les résultats sont précis. Leur rôle est essentiel pour le bon déroulement des compétitions et pour maintenir l'intégrité et l'esprit sportif du judo.</p>
              </Dropdown>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 La formation des arbitres</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4">
              <p>Des Ecoles d'arbitrage au sein des comités départementaux permettent la formation des arbitres et commissaires sportifs</p>
              <p>Niveaux d'arbitrage (départemental, régional, national, international)</p>
            </div>
            <div className="md:w-1/2 p-4">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1720731410/images/arbitrage/arbitrage_judo_02_rpacmx.jpg" alt="Formation des arbitres" className="rounded-lg shadow-md" />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default ReglementCS;
