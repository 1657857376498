import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Profile from "./pages/Profile/Profile";

import HomePageVitrine from "./pages/Home/Home";
import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import ResetPwd from "./pages/ResetPwd/ResetPwd";
import ForgotPasswordPage from "./pages/ResetPwd/ForgotPasswordPage";

import Navbar2 from "./pages/Home/Navbar2/Navbar2";
import Footer from "./pages/Home/Footer";

// Contexts
import { UserProvider } from "./contexts/UserContext.js";
import PrimaryColorProvider from "./contexts/PrimaryColorProvider.jsx";
import { NotificationProvider } from "./contexts/NotificationContext.js";
import { ContactFormProvider } from "./contexts/ContactFormContext.js";

import ErrorPage from "./pages/ErrorPage/ErrorPage";

// PageVisiteurs

// Documents
import ReglementInterieur from "./pages/Documents/ReglementInterieur";
import ConditionsUtilisationPage from "./pages/rgpd/ConditionsUtilisationPage.jsx";
import PolitiqueConfidentialitePage from "./pages/rgpd/PolitiqueConfidentialitePage.jsx";
import FaqPage from "./pages/rgpd/FaqPage.jsx";
import MentionsLegalesPage from "./pages/rgpd/MentionsLegalesPage.jsx";

// PagesUtilisateurs
import ArbitrageJudo from "./pages/Arbitrage/ArbitrageJudo";
import ReglementCS from "./pages/ReglementCS/ReglementCS";

// PagesClients
import CompetitionPage from "./pages/Competition/CompetitionPage";
import CompetitionByIdPage from "./pages/CompetitionById/CompetitionByIdPage";
import RepartitionCompetiteursPage from "./pages/RepartitionCompetiteursPage/RepartitionCompetiteursPage";
import RepartitionCompetiteursTapisPage from "./pages/RepartitionCompetiteursTapisPage/RepartitionCompetiteursTapisPage";
import SuiviCombatsPage from "./pages/SuiviCombatsPage/SuiviCombatsPage";

// PagesAdministrateurs
import UserListing from "./pages/UserListing/UserListing";
import CompetitionAdminPage from "./pages/CompetitionAdmin/CompetitionAdminPage";

// ... importez d'autres pages si nécessaire
import "./App.css";

const stripePromise = loadStripe(
  "pk_test_51POgHVEr14Icaey6JeVMxJf9bAJS9AxDAGUTSDI80b01LVDorrxErNHC1HbTU3WRgJfPD3P40daULyEzeakVDBfk00fHPS7sVp"
);

const App = () => {
  // Gestion des states
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const getLocalStorageItem = (key) => {
      try {
        console.log("Tentative de lecture du localStorage pour la clé:", key);
        const item = localStorage.getItem(key);
        console.log("Valeur obtenue:", item);
        return item ? JSON.parse(item) : null;
      } catch (error) {
        console.error(
          "Erreur lors de la lecture du localStorage pour la clé",
          key,
          ":",
          error
        );
        return null;
      }
    };

    const storedUserInfo = getLocalStorageItem("userInfo");
    if (storedUserInfo) {
      setUserInfo(storedUserInfo);
    } else {
      console.error(
        "Les informations de l'utilisateur stockées sont introuvables ou corrompues."
      );
      // Actions supplémentaires ici, comme une redirection ou une récupération depuis un serveur
    }
  }, []);

  const RequireAuth = ({ children, roles }) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo")); // Assure-toi que userInfo est stocké dans localStorage
    const location = useLocation();

    if (!userInfo) {
      return <div>Chargement...</div>; // Ou un composant de chargement plus sophistiqué
    }

    if (roles && !roles.includes(userInfo.role_id)) {
      // Rôle non autorisé donc redirection vers une page d'erreur ou d'accueil
      return <Navigate to="/unauthorized" replace />;
    }

    return children;
  };

  // Pour l'exemple, nous allons simuler un utilisateur connecté
  const user = {
    id: 1,
    nom: "Doe",
    prenom: "John",
    email: "JohnDoe@gmail.com",
    photoURL: "path_to_photo.jpg", // Remplace par le chemin réel de la photo
    role_id: 1, // 1 = Administrateur
    userId: 1,
  };

  return (
    <UserProvider>
      <Router>
        <Elements stripe={stripePromise}>
          <NotificationProvider>
            <ContactFormProvider>
              <PrimaryColorProvider>
                <div className="flex flex-col min-h-screen bg-base-200 dark:bg-gray-600 dark:text-gray-200">
                  <Navbar2
                    user={{
                      nom: userInfo?.nom ?? "Chargement...",
                      prenom: userInfo?.prenom ?? "",
                      role_id: userInfo?.role_id ?? 0,
                      photoURL: userInfo?.photoURL ?? "",
                      userId: userInfo?.id ?? 0,
                    }}
                  />
                  <Routes>
                    {/* Route pour la page d'accueil du site vitrine */}
                    <Route path="/" element={<HomePageVitrine />} />

                    {/* UTILISATEURS 2*/}
                    <Route path="/arbitrage" element={<ArbitrageJudo />} />
                    <Route path="/reglement-cs" element={<ReglementCS />} />
                    <Route
                      path="/profile"
                      element={
                        <RequireAuth roles={[2, 3, 1]}>
                          <Profile />
                        </RequireAuth>
                      }
                    />

                    {/* CLIENTS 3*/}
                    <Route
                      path="/competitions"
                      element={
                        <RequireAuth roles={[1, 3]}>
                          <CompetitionPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/competition/:id"
                      element={
                        <RequireAuth roles={[1, 3]}>
                          <CompetitionByIdPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/competition/:id/repartition"
                      element={
                        <RequireAuth roles={[1, 3]}>
                          <RepartitionCompetiteursPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/competition/:competition_id/tapis/:tapis_id"
                      element={
                        <RequireAuth roles={[1, 3]}>
                          <RepartitionCompetiteursTapisPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/competition/:competition_id/suivi-combats"
                      element={
                        <RequireAuth roles={[1, 3]}>
                          <SuiviCombatsPage />
                        </RequireAuth>
                      }
                    />

                    {/* ADMINISTRATEUR 1*/}
                    <Route
                      path="/listing-utilisateurs"
                      element={
                        <RequireAuth roles={[1]}>
                          <UserListing />
                        </RequireAuth>
                      }
                    />

                    <Route
                      path="/competitions-admin"
                      element={
                        <RequireAuth roles={[1]}>
                          <CompetitionAdminPage />
                        </RequireAuth>
                      }
                    />

                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route
                      path="/reset-password/:token"
                      element={<ResetPwd />}
                    />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPasswordPage />}
                    />
                    <Route path="/404" element={<ErrorPage />} />
                    <Route path="*" element={<ErrorPage />} />

                    <Route
                      path="/reglement-interieur"
                      element={<ReglementInterieur />}
                    />
                    <Route
                      path="/conditions-utilisation"
                      element={<ConditionsUtilisationPage />}
                    />
                    <Route
                      path="/politique-confidentialite"
                      element={<PolitiqueConfidentialitePage />}
                    />
                    <Route path="/faq" element={<FaqPage />} />
                    <Route
                      path="/mentions-legales"
                      element={<MentionsLegalesPage />}
                    />
                  </Routes>
                  <Footer />
                </div>
              </PrimaryColorProvider>
            </ContactFormProvider>
          </NotificationProvider>
        </Elements>
      </Router>
    </UserProvider>
  );
};

export default App;
