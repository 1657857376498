import React, { useState, useEffect } from "react";
import CombatModal from "./../CombatModal";
import EditCombatModal from "./../EditCombatModal";
import { getDistributionCombats } from "../../services/combatsServices";
import { calculateClassementPoule } from "../../utils/classementPouleUtils";

const RepartitionPouleSix = ({ competiteurs, distributionId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCombat, setSelectedCombat] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [combats, setCombats] = useState([]);
  const [classement, setClassement] = useState([]);
  const [classementVisible, setClassementVisible] = useState(false);
  const [positions, setPositions] = useState({ position1: null, position2: null });

  // Appel API pour récupérer les combats de la distribution
  const fetchCombats = async () => {
    try {
      const fetchedCombats = await getDistributionCombats(distributionId);
      setCombats(fetchedCombats);
      updateClassement(fetchedCombats);
    } catch (error) {
      console.error("Erreur lors de la récupération des combats :", error);
    }
  };

  useEffect(() => {
    fetchCombats();
  }, [distributionId]);

  const refreshPouleTable = () => {
    fetchCombats();
  };

  // Fonction pour mettre à jour les victoires et les points
  const updateClassement = (fetchedCombats) => {
    const classementCalc = calculateClassementPoule(competiteurs, fetchedCombats);
    setClassement(classementCalc);
  };

  // Fonction pour afficher le classement uniquement quand le bouton est cliqué
  const handleShowClassement = () => {
    setClassementVisible(!classementVisible);
  };

  // Fonction pour récupérer un combat existant
  const getCombatResult = (competiteur1Id, competiteur2Id) => {
    return combats.find(
      (combat) =>
        (combat.competiteur_id1 === competiteur1Id && combat.competiteur_id2 === competiteur2Id) ||
        (combat.competiteur_id1 === competiteur2Id && combat.competiteur_id2 === competiteur1Id)
    );
  };

  // Ouvrir la modale avec les données du combat existant ou initiales pour un nouveau combat
  const openModal = (competiteur1, competiteur2, position1, position2) => {
    const existingCombat = getCombatResult(competiteur1.id, competiteur2.id);
    const initialCombat = existingCombat
      ? existingCombat
      : {
          competiteur1: competiteur1,
          competiteur2: competiteur2,
        };

    setSelectedCombat(initialCombat);
    setPositions({ position1, position2 });
    setIsModalOpen(true);
  };

  // Ouvrir la modale d'édition pour un combat existant
  const openEditModal = (combat, position1, position2) => {
    const competiteur1 = competiteurs.find((c) => c.id === combat.competiteur_id1);
    const competiteur2 = competiteurs.find((c) => c.id === combat.competiteur_id2);

    setSelectedCombat({ ...combat, competiteur1, competiteur2 });
    setPositions({ position1, position2 });
    setIsEditModalOpen(true);
  };

  const handleModalSubmit = async () => {
    await refreshPouleTable();
  };

  // Fonction pour afficher le classement avec gestion des disqualifications et ex aequo
  const renderClassement = (competiteur) => {
    if (classementVisible) {
      const competiteurClassement = classement.find((c) => c.nom_competiteur === competiteur.nom_competiteur);

      if (!competiteurClassement) return ""; // Sécurité si aucune donnée de classement n'est trouvée

      if (competiteurClassement.disqualifie) {
        return "Disq.";
      }

      const position = competiteurClassement.position;
      return competiteurClassement.exAequo ? `${position} (ex aequo)` : position;
    }
    return "";
  };

  const renderCombatResult = (competiteur1, competiteur2, rowIndex, colIndex) => {
    // Griser les cellules pour les combats entre 1x2, 3x4, 5x6 (et leurs inverses)
    const blacklistedCombats = [
      [0, 1], [1, 0],
      [2, 3], [3, 2],
      [4, 5], [5, 4]
    ];

    if (competiteur1.id === competiteur2.id || blacklistedCombats.some(([a, b]) => a === rowIndex && b === colIndex)) {
      return <td key={`${rowIndex}-${colIndex}`} className="border px-4 py-2 bg-black"></td>;
    }

    const combat = getCombatResult(competiteur1.id, competiteur2.id);

    if (combat) {
      const formatResult = (resultat, points) => (
        <span>
          <strong className="text-base font-bold">{resultat}</strong> <small className="text-xs">({points})</small>
        </span>
      );

      const getColor = (resultat) => {
        switch (resultat) {
          case "V":
            return "bg-green-200";
          case "D":
            return "bg-red-200";
          case "N":
            return "bg-yellow-200";
          case "H":
            return "bg-red-500 text-white";
          default:
            return "";
        }
      };

      const result =
        competiteur1.id === combat.competiteur_id1
          ? formatResult(combat.resultat_competiteur1, combat.points_competiteur1)
          : formatResult(combat.resultat_competiteur2, combat.points_competiteur2);
      const color =
        competiteur1.id === combat.competiteur_id1
          ? getColor(combat.resultat_competiteur1)
          : getColor(combat.resultat_competiteur2);

      return (
        <td key={`${rowIndex}-${colIndex}`} className={`border px-4 py-2 ${color} text-center`}>
          {result}
        </td>
      );
    }

    return <td key={`${rowIndex}-${colIndex}`} className="border px-4 py-2 text-center"></td>;
  };

  const isCombatDone = (competiteur1Id, competiteur2Id) => !!getCombatResult(competiteur1Id, competiteur2Id);

  const handleCombatButtonClick = (competiteur1, competiteur2, position1, position2) => {
    const existingCombat = getCombatResult(competiteur1.id, competiteur2.id);

    if (existingCombat) {
      openEditModal(existingCombat, position1, position2);
    } else {
      openModal(competiteur1, competiteur2, position1, position2);
    }
  };

  return (
    <div>
      <div className="flex items-center mb-4">
        <h2 className="text-xl font-bold">Tableau de Poule de 6</h2>
        <button
          onClick={refreshPouleTable}
          className="ml-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Recharger
        </button>
        <button
          onClick={handleShowClassement}
          className="ml-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          {classementVisible ? "Masquer Classement" : "Afficher Classement"}
        </button>
      </div>
      <table className="table-auto border-collapse w-full">
        <thead>
          <tr className="bg-gray-400 text-gray-800 text-sm">
            <th className="border px-2 py-2">N°</th>
            <th className="border px-2 py-2">Compétiteurs</th>
            <th className="border px-2 py-2">Clubs</th>
            <th className="border px-2 py-2">Poids</th>
            <th className="border px-2 py-2">1</th>
            <th className="border px-2 py-2">2</th>
            <th className="border px-2 py-2">3</th>
            <th className="border px-2 py-2">4</th>
            <th className="border px-2 py-2">5</th>
            <th className="border px-2 py-2">6</th>
            <th className="border px-2 py-2">V</th>
            <th className="border px-2 py-2">Pts</th>
            <th className="border px-2 py-2">Class.</th>
          </tr>
        </thead>
        <tbody>
          {competiteurs.map((competiteur, rowIndex) => (
            <tr key={competiteur.id}>
              <td className="border px-2 py-2 text-center text-sm font-semibold">{rowIndex + 1}</td>
              <td className="border px-2 py-2 text-left text-sm font-semibold">
                {competiteur.nom_competiteur} {competiteur.prenom_competiteur}
              </td>
              <td className="border px-2 py-2 text-left text-xs font-thin">{competiteur.club_competiteur}</td>
              <td className="border px-2 py-2 text-center text-xs font-thin">{competiteur.poids_competiteur}</td>

              {competiteurs.map((opposant, colIndex) =>
                renderCombatResult(competiteur, opposant, rowIndex, colIndex)
              )}

              <td className="border px-2 py-2 text-center text-sm font-semibold">
                {classement.find((c) => c.nom_competiteur === competiteur.nom_competiteur)?.victoires || 0}
              </td>
              <td className="border px-2 py-2 text-center text-sm font-semibold">
                {classement.find((c) => c.nom_competiteur === competiteur.nom_competiteur)?.points || 0}
              </td>
              <td
                className={`border px-2 py-2 text-center text-sm font-semibold ${
                  classement.find((c) => c.nom_competiteur === competiteur.nom_competiteur)?.disqualifie
                    ? "bg-gray-300"
                    : ""
                }`}
              >
                {renderClassement(competiteur)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-4">
        <button
          className={`px-4 py-2 rounded text-white ${isCombatDone(competiteurs[1].id, competiteurs[5].id) ? 'bg-green-500' : 'bg-blue-500'}`}
          onClick={() => handleCombatButtonClick(competiteurs[1], competiteurs[5], 2, 6)}
        >
          2x6
        </button>
        <button
          className={`px-4 py-2 rounded text-white ml-4 ${isCombatDone(competiteurs[0].id, competiteurs[4].id) ? 'bg-green-500' : 'bg-blue-500'}`}
          onClick={() => handleCombatButtonClick(competiteurs[0], competiteurs[4], 1, 5)}
        >
          1x5
        </button>
        <button
          className={`px-4 py-2 rounded text-white ml-4 ${isCombatDone(competiteurs[3].id, competiteurs[5].id) ? 'bg-green-500' : 'bg-blue-500'}`}
          onClick={() => handleCombatButtonClick(competiteurs[3], competiteurs[5], 4, 6)}
        >
          4x6
        </button>
        <button
          className={`px-4 py-2 rounded text-white ml-4 ${isCombatDone(competiteurs[1].id, competiteurs[2].id) ? 'bg-green-500' : 'bg-blue-500'}`}
          onClick={() => handleCombatButtonClick(competiteurs[1], competiteurs[2], 2, 3)}
        >
          2x3
        </button>
        <button
          className={`px-4 py-2 rounded text-white ml-4 ${isCombatDone(competiteurs[0].id, competiteurs[5].id) ? 'bg-green-500' : 'bg-blue-500'}`}
          onClick={() => handleCombatButtonClick(competiteurs[0], competiteurs[5], 1, 6)}
        >
          1x6
        </button>
        <button
          className={`px-4 py-2 rounded text-white ml-4 ${isCombatDone(competiteurs[3].id, competiteurs[4].id) ? 'bg-green-500' : 'bg-blue-500'}`}
          onClick={() => handleCombatButtonClick(competiteurs[3], competiteurs[4], 4, 5)}
        >
          4x5
        </button>
        <button
          className={`px-4 py-2 rounded text-white ml-4 ${isCombatDone(competiteurs[0].id, competiteurs[2].id) ? 'bg-green-500' : 'bg-blue-500'}`}
          onClick={() => handleCombatButtonClick(competiteurs[0], competiteurs[2], 1, 3)}
        >
          1x3
        </button>
        <button
          className={`px-4 py-2 rounded text-white ml-4 ${isCombatDone(competiteurs[1].id, competiteurs[4].id) ? 'bg-green-500' : 'bg-blue-500'}`}
          onClick={() => handleCombatButtonClick(competiteurs[1], competiteurs[4], 2, 5)}
        >
          2x5
        </button>
        <button
          className={`px-4 py-2 rounded text-white ml-4 ${isCombatDone(competiteurs[2].id, competiteurs[5].id) ? 'bg-green-500' : 'bg-blue-500'}`}
          onClick={() => handleCombatButtonClick(competiteurs[2], competiteurs[5], 3, 6)}
        >
          3x6
        </button>
        <button
          className={`px-4 py-2 rounded text-white ml-4 ${isCombatDone(competiteurs[0].id, competiteurs[3].id) ? 'bg-green-500' : 'bg-blue-500'}`}
          onClick={() => handleCombatButtonClick(competiteurs[0], competiteurs[3], 1, 4)}
        >
          1x4
        </button>
        <button
          className={`px-4 py-2 rounded text-white ml-4 ${isCombatDone(competiteurs[2].id, competiteurs[4].id) ? 'bg-green-500' : 'bg-blue-500'}`}
          onClick={() => handleCombatButtonClick(competiteurs[2], competiteurs[4], 3, 5)}
        >
          3x5
        </button>
        <button
          className={`px-4 py-2 rounded text-white ml-4 ${isCombatDone(competiteurs[1].id, competiteurs[3].id) ? 'bg-green-500' : 'bg-blue-500'}`}
          onClick={() => handleCombatButtonClick(competiteurs[1], competiteurs[3], 2, 4)}
        >
          2x4
        </button>
      </div>

      <CombatModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        competiteur1={selectedCombat?.competiteur1}
        competiteur2={selectedCombat?.competiteur2}
        onSubmit={handleModalSubmit}
        initialData={selectedCombat || {}}
        distributionId={distributionId}
        position1={positions.position1}
        position2={positions.position2}
        refreshPouleTable={refreshPouleTable}
      />
      <EditCombatModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        competiteur1={selectedCombat?.competiteur1}
        competiteur2={selectedCombat?.competiteur2}
        initialData={selectedCombat}
        distributionId={distributionId}
        position1={positions.position1}
        position2={positions.position2}
        refreshPouleTable={refreshPouleTable}
      />
    </div>
  );
};

export default RepartitionPouleSix;
