import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/combats`;
const token = localStorage.getItem("token");

/// GET

// Fonction pour récupérer tous les combats
export const getAllCombats = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allCombats`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement des combats:", error);
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour récupérer un combat avec son id
export const getCombatById = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/combats/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement du combat:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour afficher les combats d'une compétition par son id
export const getCompetitionCombats = async (competitionId) => {
  try {
    const response = await axios.get(`${apiUrl}/competition/${competitionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error("Failed to fetch competition combats");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors du chargement des combats de la compétition:",
      error
    );
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour afficher les combats d'une distribution par son id
export const getDistributionCombats = async (distributionId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/distribution/${distributionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200)
      throw new Error("Failed to fetch distribution combats");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors du chargement des combats de la distribution:",
      error
    );
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// POST
export const createCombat = async (combatData) => {
  try {
    const response = await axios.post(`${apiUrl}/addCombat`, combatData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 201) throw new Error("Failed to create combat");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la création du combat:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// PUT
// Fonction pour mettre à jour un combat avec son id
export const updateCombat = async (id, combatData) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateCombat/${id}`,
      combatData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to update combat");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la mise à jour du combat:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// DELETE
// Fonction pour supprimer un combat avec son id
export const deleteCombat = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteCombat/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to delete combat");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la suppression du combat:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};
