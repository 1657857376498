import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import {
  getAllCompetitions,
  addCompetition,
  deleteCompetition,
} from "../../services/competitionsServices";
import { getAllUsers } from "../../services/userServices";
import Button from "./../../components/Button/Button";
import ButtonEdit from "./../../components/Button/ButtonEdit";
import ButtonDelete from "./../../components/Button/ButtonDelete";
import useScrollToTop from "../../hooks/useScrollToTop";
import { useUser } from "./../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../utils/roleHelper";
import ScrollIndicator from "./../../hooks/ScrollIndicator";
import EditCompetitionAdminModal from "./EditCompetitionAdminModal"; // Import de la modale d'édition

import { ToastContainer, toast } from "react-toastify";

const CompetitionAdminPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  const [competitions, setCompetitions] = useState([]);
  const [users, setUsers] = useState([]);
  const [newCompetition, setNewCompetition] = useState({
    nom_competition: "",
    level_competition: "",
    lieu_competition: "",
    date_competition: "",
    organisateur_competition: "",
    is_active: true,
  });
  const [editModalOpen, setEditModalOpen] = useState(false); // État pour contrôler la modale d'édition
  const [currentCompetitionId, setCurrentCompetitionId] = useState(null); // ID de la compétition à éditer

  // Charger toutes les compétitions et les utilisateurs (pour le select organisateur)
  const fetchCompetitionsAndUsers = async () => {
    try {
      const competitionsData = await getAllCompetitions();
      const usersData = await getAllUsers();
      setCompetitions(competitionsData);
      setUsers(usersData);
    } catch (error) {
      toast.error(
        "Erreur lors du chargement des compétitions ou des utilisateurs"
      );
    }
  };

  useEffect(() => {
    fetchCompetitionsAndUsers();
  }, []);

  // Gérer la suppression d'une compétition
  const handleDeleteCompetition = async (id) => {
    if (
      window.confirm("Êtes-vous sûr de vouloir supprimer cette compétition ?")
    ) {
      try {
        await deleteCompetition(id);
        toast.success("Compétition supprimée avec succès");
        setCompetitions(competitions.filter((comp) => comp.id !== id));
      } catch (error) {
        toast.error("Erreur lors de la suppression de la compétition");
      }
    }
  };

  // Ouvrir la modale d'édition avec l'ID de la compétition
  const handleOpenEditModal = (competitionId) => {
    setCurrentCompetitionId(competitionId);
    setEditModalOpen(true);
  };

  // Gérer la soumission du formulaire pour ajouter une nouvelle compétition
  const handleAddCompetition = async (e) => {
    e.preventDefault();
    try {
      await addCompetition(newCompetition);
      toast.success("Compétition ajoutée avec succès");

      // Recharger les compétitions après l'ajout
      await fetchCompetitionsAndUsers();

      setNewCompetition({
        nom_competition: "",
        level_competition: "",
        lieu_competition: "",
        date_competition: "",
        organisateur_competition: "",
        is_active: true,
      });
    } catch (error) {
      toast.error("Erreur lors de l'ajout de la compétition");
    }
  };

  // Gérer les changements dans le formulaire d'ajout
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCompetition({
      ...newCompetition,
      [name]: value,
    });
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Listing Compétitions
      </h2>

      <div className=" w-full max-w-[1280px] mx-auto">
        {/* Formulaire pour ajouter une nouvelle compétition */}
        <form onSubmit={handleAddCompetition} className="mb-8">
          <h3 className="text-xl mb-4">Ajouter une nouvelle compétition</h3>
          <div className="grid grid-cols-2 gap-4 mb-8">
            <input
              type="text"
              name="nom_competition"
              placeholder="Nom de la compétition"
              value={newCompetition.nom_competition}
              onChange={handleInputChange}
              required
              className="input input-bordered"
            />
            <input
              type="text"
              name="level_competition"
              placeholder="Niveau"
              value={newCompetition.level_competition}
              onChange={handleInputChange}
              required
              className="input input-bordered"
            />
            <input
              type="text"
              name="lieu_competition"
              placeholder="Lieu"
              value={newCompetition.lieu_competition}
              onChange={handleInputChange}
              required
              className="input input-bordered"
            />
            <input
              type="date"
              name="date_competition"
              placeholder="Date"
              value={newCompetition.date_competition}
              onChange={handleInputChange}
              required
              className="input input-bordered"
            />

            {/* Select pour l'organisateur */}
            <select
              name="organisateur_competition"
              value={newCompetition.organisateur_competition}
              onChange={handleInputChange}
              required
              className="input input-bordered"
            >
              <option value="">Sélectionner un organisateur</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.nom} {user.prenom}
                </option>
              ))}
            </select>
          </div>

          <div className="shadow-xl w-full max-w-[1280px] mx-auto">
            <Button
              text="Ajouter une Compétition"
              icon={FaPlus}
              className="w-full gap-2"
            />
          </div>
        </form>

        {/* Tableau des compétitions */}
        <div className="overflow-x-auto shadow-lg mb-8">
          <table className="table-auto w-full">
            <thead>
              <tr className="bg-gray-800 text-white">
                <th className="px-4 py-2">Active</th>
                <th className="px-4 py-2">Nom</th>
                <th className="px-4 py-2">Niveau</th>
                <th className="px-4 py-2">Lieu</th>
                <th className="px-4 py-2">Date</th>
                <th className="px-4 py-2">Organisateur</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {competitions.map((competition) => {
                const organisateur = users.find(
                  (user) => user.id === competition.organisateur_competition
                );

                return (
                  <tr key={competition.id} className="border-b">
                    <td className="px-4 py-2 text-center">
                      {competition.is_active ? "✅" : "❌"}
                    </td>
                    <td className="px-4 py-2">{competition.nom_competition}</td>
                    <td className="px-4 py-2">
                      {competition.level_competition}
                    </td>
                    <td className="px-4 py-2">
                      {competition.lieu_competition}
                    </td>
                    <td className="px-4 py-2">
                      {new Date(
                        competition.date_competition
                      ).toLocaleDateString()}
                    </td>
                    <td className="px-4 py-2">
                      {organisateur
                        ? `${organisateur.nom} ${organisateur.prenom}`
                        : "Inconnu"}
                    </td>
                    <td className="px-4 py-2 flex gap-2">
                      <ButtonEdit
                        onClick={() => handleOpenEditModal(competition.id)}
                      />
                      <ButtonDelete
                        onClick={() => handleDeleteCompetition(competition.id)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modale d'édition */}
      <EditCompetitionAdminModal
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        refreshList={fetchCompetitionsAndUsers}
        competitionId={currentCompetitionId}
      />

      <ToastContainer />
    </div>
  );
};

export default CompetitionAdminPage;
