import React, { useState, useEffect } from 'react';
import useScrollToTop from "../../hooks/useScrollToTop";
import { getSettings } from '../../services/settingsServices';

const MentionsLegalesPage = () => {
  // Scroll to top screen
  useScrollToTop();

  const [settings, setSettings] = useState({
    siteweb_club: '',
    nom_club: '',
    adresse_club: '',
    responsable_club: '',
    mail_club: '',
    siret_club: '',
  });

  useEffect(() => {
    const fetchSettings = async () => {
      const data = await getSettings();
      if (data.length > 0) {
        setSettings(data[0]);
      }
    };

    fetchSettings();
  }, []);

  return (
    <div>
      <div className='flex flex-col w-full max-w-[1280px] mx-auto p-6 px-10 mt-[120px] bg-backgroundMain dark:bg-backgroundMainDark dark:text-white '>
        <h1 className='text-xl tracking-widest text-gray-800 text-center dark:text-white'>Textes Réglementaires</h1>
        <h2 className='text-4xl font-bold uppercase text-center my-6 mb-10'>Mentions Légales</h2>

        {/* Header image */}
        <div className="text-center mb-4">
          <img src="/img/inscription.png" alt="Inscription" className="mx-auto" />
        </div>

        {/* Content Section */}
        <div className='flex flex-col space-y-4'>
          <h3 className='text-2xl font-bold'>1. Informations légales</h3>
          <p>
            En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site
            internet <b>{settings.siteweb_club}</b> l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :
          </p>
          <ul className='list-disc pl-5'>
            <li>
              <strong>Propriétaire du site :</strong> <b>{settings.nom_club}</b> – Association Sportive Loi 1901 – <b>{settings.adresse_club}</b>
            </li>
            <li>
              <strong>Responsable publication :</strong> <b>{settings.responsable_club}</b> – <b>{settings.mail_club}</b>
            </li>
            <li>
              <strong>Webmaster :</strong> VICTORIN Laurent – victorinlaurent33@gmail.com
            </li>
            <li>
              <strong>Hébergeur :</strong> Hostinger – https://www.hostinger.fr/
            </li>
            <li>
              <strong>Numéro de SIRET :</strong> <b>{settings.siret_club}</b>
            </li>
          </ul>

          <h3 className='text-2xl font-bold'>2. Conditions générales d’utilisation du site et des services proposés</h3>
          <p>
            L’utilisation du site <b>{settings.siteweb_club}</b> implique l’acceptation pleine et entière des conditions générales d’utilisation décrites ci-dessous. Ces
            conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site sont donc invités à les consulter
            de manière régulière.
          </p>

          <h3 className='text-2xl font-bold'>3. Description des services fournis</h3>
          <p>
            Le site <b>{settings.siteweb_club}</b> a pour objet de fournir une information concernant l’ensemble des activités de l'association. Le propriétaire du site
            s’efforce de fournir sur le site des informations aussi précises que possible. Toutefois, il ne pourra être tenue responsable des omissions, des
            inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces
            informations.
          </p>

          <h3 className='text-2xl font-bold'>4. Propriété intellectuelle et contrefaçons</h3>
          <p>
            <b>{settings.nom_club}</b> est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur
            le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels… Toute reproduction, représentation, modification, publication,
            adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable
            de : <b>{settings.nom_club}</b>. Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme
            constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
          </p>

          <h3 className='text-2xl font-bold'>5. Limitations de responsabilité</h3>
          <p>
            <b>{settings.nom_club}</b> ne pourra être tenue responsable des dommages directs et indirects causés au matériel de l'utilisateur, lors de l’accès au
            site <b>{settings.siteweb_club}</b>, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées, soit de l’apparition d’un
            bug ou d’une incompatibilité. <b>{settings.nom_club}</b> ne pourra également être tenue responsable des dommages indirects (tels que la perte de marché
            ou perte d’une chance) consécutifs à l’utilisation du site <b>{settings.siteweb_club}</b>.
          </p>

          <h3 className='text-2xl font-bold'>6. Gestion des données personnelles</h3>
          <p>
            En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l'article L.
            226-13 du Code pénal et la Directive Européenne du 24 octobre 1995. À l'occasion de l'utilisation du site <b>{settings.siteweb_club}</b>, peuvent êtres
            recueillies : l'URL des liens par l'intermédiaire desquels l'utilisateur a accédé au site <b>{settings.siteweb_club}</b>, le fournisseur d'accès de
            l'utilisateur, l'adresse de protocole Internet (IP) de l'utilisateur. En tout état de cause <b>{settings.nom_club}</b> ne collecte des informations
            personnelles relatives à l'utilisateur que pour le besoin de certains services proposés par le site <b>{settings.siteweb_club}</b>. L'utilisateur fournit ces
            informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'utilisateur du site <b>{settings.siteweb_club}</b> l’obligation ou non de fournir ces informations. Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6
            janvier 1978 relative à l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un droit d’accès, de rectification et d’opposition
            aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d’une copie du titre d’identité avec signature du
            titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit être envoyée.
          </p>

          <h3 className='text-2xl font-bold'>7. Liens hypertextes et cookies</h3>
          <p>
            Le site <b>{settings.siteweb_club}</b> contient un certain nombre de liens hypertextes vers d’autres sites, mis en place avec l’autorisation de <b>{settings.nom_club}</b>. Cependant, <b>{settings.nom_club}</b> n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en
            conséquence aucune responsabilité de ce fait. La navigation sur le site <b>{settings.siteweb_club}</b> est susceptible de provoquer l’installation de cookie(s)
            sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l'utilisateur, mais qui
            enregistre des informations relatives à la navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation
            ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation. Le refus d’installation d’un cookie peut entraîner
            l’impossibilité d’accéder à certains services. L’utilisateur peut toutefois configurer son ordinateur pour refuser l’installation des cookies.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MentionsLegalesPage;
