import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCompetitionById } from "../../services/competitionsServices";
import { getCompetitionTapisDistributions } from "../../services/distributionsServices";
import { ToastContainer, toast } from "react-toastify";
import ScrollIndicator from "../../hooks/ScrollIndicator";
import useScrollToTop from "../../hooks/useScrollToTop";

import RepartitionPouleTrois from "../../components/FormulesRepartitions/RepartitionPouleTrois";
import RepartitionPouleQuatre from "../../components/FormulesRepartitions/RepartitionPouleQuatre";
import RepartitionPouleCinq from "../../components/FormulesRepartitions/RepartitionPouleCinq";
import RepartitionPouleSix from "../../components/FormulesRepartitions/RepartitionPouleSix";
import RepartitionTableauSansRepechage from "../../components/FormulesRepartitions/RepartitionTableauSansRepechage";
import RepartitionTableauDoubleRepechage from "../../components/FormulesRepartitions/RepartitionTableauDoubleRepechage"; // Importation du composant pour le double repêchage

const RepartitionCompetiteursTapisPage = () => {
  useScrollToTop();
  const { competition_id, tapis_id } = useParams();
  const [competition, setCompetition] = useState(null);
  const [distributions, setDistributions] = useState([]);
  const [countDistributedCompetiteursByDistribution, setCountDistributedCompetiteursByDistribution] = useState([]);
  const [selectedDistribution, setSelectedDistribution] = useState(null);

  useEffect(() => {
    const fetchCompetition = async () => {
      try {
        const competitionData = await getCompetitionById(competition_id);
        setCompetition(competitionData);
      } catch (error) {
        toast.error("Erreur lors du chargement de la compétition");
      }
    };

    const fetchDistributions = async () => {
      try {
        const { distributions: distributionsData, countDistributedCompetiteursByDistribution: countData } = await getCompetitionTapisDistributions(
          competition_id,
          tapis_id
        );
        setDistributions(distributionsData);
        setCountDistributedCompetiteursByDistribution(countData);
      } catch (error) {
        toast.error("Erreur lors du chargement des distributions pour ce tapis");
      }
    };

    fetchCompetition();
    fetchDistributions();
  }, [competition_id, tapis_id]);

  const getCompetitorCountForDistribution = (distributionId) => {
    const distribution = countDistributedCompetiteursByDistribution.find((d) => d.id === distributionId);
    return distribution ? distribution.count : 0;
  };

  const handleCardClick = (distribution) => {
    setSelectedDistribution(distribution);
  };

  if (!competition) {
    return <p>Chargement des détails de la compétition...</p>;
  }

  return (
    <div className="flex flex-col w-full mx-auto p-4 mt-[130px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        Tapis {tapis_id}
      </h2>
      <div className="bg-white dark:bg-gray-800 shadow-md p-4 rounded-lg mx-auto">
        <h3 className="text-lg font-bold mb-4 font-lato">{competition.nom_competition}</h3>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {distributions.map((distribution) => (
            <div
              key={distribution.id}
              className={`rounded-lg shadow p-4 cursor-pointer ${
                selectedDistribution && selectedDistribution.id === distribution.id
                  ? 'bg-blue-200 dark:bg-blue-600'
                  : 'bg-gray-100 dark:bg-gray-700'
              }`} // Appliquer le style conditionnel
              onClick={() => handleCardClick(distribution)}
            >
              <h4 className="text-lg font-semibold">Distribution {distribution.id}</h4>
              <p>
                <strong>Formule :</strong> {distribution.formule_repartition}
              </p>
              <p>
                <strong>Genre :</strong> {distribution.genre}
              </p>
              <p>
                <strong>Catégorie d'âge :</strong> {distribution.categorie_age}
              </p>
              <p>
                <strong>Catégorie de poids :</strong> {distribution.categorie_poids}
              </p>
              <p>
                <strong>Numéro de tapis :</strong> {distribution.numero_tapis}
              </p>
              <p>
                <strong>Nombre de compétiteurs :</strong> {getCompetitorCountForDistribution(distribution.id)}
              </p>
            </div>
          ))}
        </div>
      </div>

      {selectedDistribution && selectedDistribution.formule_repartition === "poule_3" && (
        <div className="mt-8">
          <RepartitionPouleTrois competiteurs={selectedDistribution.Competiteurs} distributionId={selectedDistribution.id} />
        </div>
      )}

      {selectedDistribution && selectedDistribution.formule_repartition === "poule_4" && (
        <div className="mt-8">
          <RepartitionPouleQuatre competiteurs={selectedDistribution.Competiteurs} distributionId={selectedDistribution.id} />
        </div>
      )}

      {selectedDistribution && selectedDistribution.formule_repartition === "poule_5" && (
        <div className="mt-8">
          <RepartitionPouleCinq competiteurs={selectedDistribution.Competiteurs} distributionId={selectedDistribution.id} />
        </div>
      )}

      {selectedDistribution && selectedDistribution.formule_repartition === "poule_6" && (
        <div className="mt-8">
          <RepartitionPouleSix competiteurs={selectedDistribution.Competiteurs} distributionId={selectedDistribution.id} />
        </div>
      )}

      {selectedDistribution && selectedDistribution.formule_repartition === "tableau_sans_repechage" && (
        <div className="mt-8">
          <RepartitionTableauSansRepechage
            competiteurs={selectedDistribution.Competiteurs} distributionId={selectedDistribution.id} 
          />
        </div>
      )}

      {selectedDistribution && selectedDistribution.formule_repartition === "tableau_double_repechage" && getCompetitorCountForDistribution(selectedDistribution.id) === 23 && (
        <div className="mt-8">
          <RepartitionTableauDoubleRepechage
            competiteurs={selectedDistribution.Competiteurs}
            distributionId={selectedDistribution.id}
          />
        </div>
      )}
      
      <ToastContainer />
    </div>
  );
};

export default RepartitionCompetiteursTapisPage;
