import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getCompetitionById } from "../../services/competitionsServices";
import { getCompetitionCompetiteurs, updateCompetiteur } from "../../services/competiteursServices";
import { addDistribution, getCompetitionDistributions } from "../../services/distributionsServices";
import { ToastContainer, toast } from "react-toastify";
import getCategory from "../../utils/getCategory";
import getWeightCategory from "../../utils/getWeightCategory";
import generateRandomPositions from "../../utils/generateRandomPositions"; // Import de la fonction
import ScrollIndicator from "../../hooks/ScrollIndicator";
import useScrollToTop from "../../hooks/useScrollToTop";

const RepartitionCompetiteursPage = () => {
  useScrollToTop();
  const { id } = useParams(); 
  const [competition, setCompetition] = useState(null);
  const [competiteurs, setCompetiteurs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [groupedCompetiteurs, setGroupedCompetiteurs] = useState([]);
  const [distributions, setDistributions] = useState([]);
  const [selectedCompetitors, setSelectedCompetitors] = useState([]); 
  const [selectAllByCategory, setSelectAllByCategory] = useState({}); 
  const [filterGender, setFilterGender] = useState("all");
  const [filterAgeCategory, setFilterAgeCategory] = useState("all");
  const [distributionData, setDistributionData] = useState({}); 

  const ageCategoryOrder = ["Eveil", "Mini-Poussin", "Poussin", "Benjamin", "Cadet", "Junior", "Sénior"];
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const competitionData = await getCompetitionById(id);
        const competiteursData = await getCompetitionCompetiteurs(id);
        const distributionsData = await getCompetitionDistributions(id); 
        setCompetition(competitionData);
        setCompetiteurs(competiteursData);
        setDistributions(distributionsData);
        groupCompetiteurs(competiteursData);
        setLoading(false);
      } catch (error) {
        toast.error("Erreur lors du chargement des données.");
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const tapisDisponibles = [...new Set(distributions.map((dist) => dist.numero_tapis))].sort((a, b) => a - b);

  const groupCompetiteurs = (competiteurs) => {
    const grouped = {};
    competiteurs.forEach((competiteur) => {
      if (
        competiteur.is_present &&
        (filterGender === "all" || competiteur.genre_competiteur === filterGender)
      ) {
        const ageCategory = getCategory(competiteur.ddn_competiteur);
        if (filterAgeCategory !== "all" && ageCategory !== filterAgeCategory) {
          return;
        }

        const weightCategory = getWeightCategory(
          competiteur.genre_competiteur,
          ageCategory,
          competiteur.poids_competiteur
        );
        const genre = competiteur.genre_competiteur;
        const key = `${genre} - ${ageCategory} - ${weightCategory}`;
        if (!grouped[key]) {
          grouped[key] = [];
        }
        grouped[key].push(competiteur);
      }
    });

    const sortedGroups = Object.keys(grouped)
      .sort((a, b) => {
        const ageA = a.split(" - ")[1];
        const ageB = b.split(" - ")[1];
        const ageComparison = ageCategoryOrder.indexOf(ageA) - ageCategoryOrder.indexOf(ageB);
        if (ageComparison !== 0) return ageComparison;
        const weightA = parseFloat(a.split(" - ")[2].replace(/[^\d.]/g, ""));
        const weightB = parseFloat(b.split(" - ")[2].replace(/[^\d.]/g, ""));
        return weightA - weightB;
      })
      .map((key) => [key, grouped[key].sort((a, b) => a.poids_competiteur - b.poids_competiteur)]);

    setGroupedCompetiteurs(sortedGroups);
  };

  const handleSelectAllByCategory = (category) => {
    const isSelected = selectAllByCategory[category];
    const competitorsInCategory = groupedCompetiteurs.find(([cat]) => cat === category)[1];
    if (!isSelected) {
      const newSelected = [
        ...selectedCompetitors,
        ...competitorsInCategory.map((competitor) => competitor.id),
      ];
      setSelectedCompetitors(newSelected);
    } else {
      const newSelected = selectedCompetitors.filter(
        (id) => !competitorsInCategory.some((competitor) => competitor.id === id)
      );
      setSelectedCompetitors(newSelected);
    }
    setSelectAllByCategory((prev) => ({
      ...prev,
      [category]: !isSelected,
    }));
  };

  const handleSelectCompetitor = (id) => {
    setSelectedCompetitors((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((competitorId) => competitorId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDistributionChange = (category, field, value) => {
    setDistributionData((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [field]: value,
      },
    }));
  };

  // Utiliser l'API addDistribution pour soumettre les compétiteurs sélectionnés avec positions aléatoires
  const handleSubmitDistribution = async (category, competiteurs) => {
    const { formule_repartition, numero_tapis } = distributionData[category] || {};

    if (!formule_repartition || !numero_tapis) {
      toast.error("Veuillez sélectionner une formule de répartition et un numéro de tapis.");
      return;
    }

    const competiteursCoches = competiteurs.filter((competiteur) =>
      selectedCompetitors.includes(competiteur.id)
    );

    if (competiteursCoches.length === 0) {
      toast.error("Veuillez sélectionner au moins un compétiteur.");
      return;
    }

    try {
      const positionsAleatoires = generateRandomPositions(competiteursCoches);

      // Enregistrer la distribution avec positions aléatoires
      const distribution = await addDistribution({
        numero_tapis,
        formule_repartition,
        genre: category.split(" - ")[0],
        categorie_age: category.split(" - ")[1],
        categorie_poids: category.split(" - ")[2],
        competiteurs: competiteursCoches.map((c) => c.id),
        position_aleatoire: positionsAleatoires, // Ajout des positions
        competition_id: id,
      });

      for (const competiteur of competiteursCoches) {
        await updateCompetiteur(competiteur.id, { is_distributed: true, distribution_id: distribution.id });
      }

      toast.success("Distribution enregistrée avec succès.");
      const updatedCompetiteurs = await getCompetitionCompetiteurs(id);
      setCompetiteurs(updatedCompetiteurs);
      groupCompetiteurs(updatedCompetiteurs);
      setSelectedCompetitors([]);

    } catch (error) {
      toast.error("Erreur lors de la soumission de la distribution.");
    }
  };

  useEffect(() => {
    if (competiteurs.length > 0) {
      groupCompetiteurs(competiteurs);
    }
  }, [filterGender, filterAgeCategory, competiteurs]);

  if (loading) return <p>Chargement...</p>;

  return (
    <div className="flex flex-col w-full mx-auto p-4 mt-[130px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        Répartition des Compétiteurs
      </h2>
      <div className="flex justify-center mb-4">
        {tapisDisponibles.length > 0 ? (
          tapisDisponibles.map((tapis) => (
            <button
              key={tapis}
              onClick={() => navigate(`/competition/${id}/tapis/${tapis}`)}
              className="bg-blue-500 text-white p-2 m-2 rounded-lg"
            >
              Tapis {tapis}
            </button>
          ))
        ) : (
          <p>Aucun tapis trouvé pour cette compétition</p>
        )}
      </div>
      <div className="bg-white dark:bg-gray-800 shadow-md p-4 rounded-lg mx-auto">
        <h3 className="text-lg font-bold mb-4 font-lato">
          {competition.nom_competition}
        </h3>

        <div className="mb-4">
          <label htmlFor="filterGender" className="block text-sm font-medium">
            Filtrer par genre :
          </label>
          <select
            id="filterGender"
            value={filterGender}
            onChange={(e) => setFilterGender(e.target.value)}
            className="border p-2 w-full mt-1"
          >
            <option value="all">Tous</option>
            <option value="M">Masculin</option>
            <option value="F">Féminin</option>
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="filterAgeCategory" className="block text-sm font-medium">
            Filtrer par catégorie d'âge :
          </label>
          <select
            id="filterAgeCategory"
            value={filterAgeCategory}
            onChange={(e) => setFilterAgeCategory(e.target.value)}
            className="border p-2 w-full mt-1"
          >
            <option value="all">Toutes</option>
            {ageCategoryOrder.map((ageCategory) => (
              <option key={ageCategory} value={ageCategory}>
                {ageCategory}
              </option>
            ))}
          </select>
        </div>

        {groupedCompetiteurs.length === 0 ? (
          <p>Aucun compétiteur à afficher.</p>
        ) : (
          groupedCompetiteurs.map(([category, competiteurs]) => (
            <div key={category} className="mb-8">
              <h4 className="text-md font-semibold mb-2">
                {category} ({competiteurs.length} compétiteurs)
              </h4>
              <table className="table-auto w-full bg-white shadow-md text-xs font-lato">
                <thead>
                  <tr className="bg-gray-800 text-white">
                    <th className="px-4 py-2">
                      <input
                        type="checkbox"
                        checked={selectAllByCategory[category] || false}
                        onChange={() => handleSelectAllByCategory(category)}
                      />
                    </th>
                    <th className="px-4 py-2">Nom</th>
                    <th className="px-4 py-2">Prénom</th>
                    <th className="px-4 py-2">Poids (kg)</th>
                    <th className="px-4 py-2">Club</th>
                    <th className="px-4 py-2">Distribution</th>
                  </tr>
                </thead>
                <tbody>
                  {competiteurs.map((competiteur) => (
                    <tr
                      key={competiteur.id}
                      className={`border px-4 py-2 ${
                        competiteur.is_distributed ? "bg-gray-300" : competiteur.genre_competiteur === "F" ? "bg-pink-200" : "bg-blue-200"
                      }`}
                    >
                      <td className="border px-4 py-2">
                        <input
                          type="checkbox"
                          checked={selectedCompetitors.includes(competiteur.id)}
                          disabled={competiteur.is_distributed}
                          onChange={() => handleSelectCompetitor(competiteur.id)}
                        />
                      </td>
                      <td className="border px-4 py-2">
                        {competiteur.nom_competiteur}
                      </td>
                      <td className="border px-4 py-2">
                        {competiteur.prenom_competiteur}
                      </td>
                      <td className="border px-4 py-2">
                        {competiteur.poids_competiteur.toFixed(1)}
                      </td>
                      <td className="border px-4 py-2">
                        {competiteur.club_competiteur}
                      </td>
                      <td className="border px-4 py-2">
                        {competiteur.is_distributed ? competiteur.distribution_id : "Non réparti"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex space-x-4 mt-4">
                <div>
                  <label className="block text-sm font-medium">Formule de répartition :</label>
                  <select
                    value={distributionData[category]?.formule_repartition || ""}
                    onChange={(e) =>
                      handleDistributionChange(category, "formule_repartition", e.target.value)
                    }
                    className="border p-2 mt-1 w-full"
                  >
                    <option value="">Sélectionnez une formule</option>
                    <option value="poule_3">Poule de 3</option>
                    <option value="poule_4">Poule de 4</option>
                    <option value="poule_5">Poule de 5</option>
                    <option value="poule_6">Poule de 6</option>
                    <option value="tableau_sans_repechage">Tableau sans repêchage</option>
                    <option value="tableau_simple_repechage">Tableau avec simple repêchage</option>
                    <option value="tableau_double_repechage">Tableau avec double repêchage</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium">Numéro de tapis :</label>
                  <input
                    type="number"
                    value={distributionData[category]?.numero_tapis || ""}
                    onChange={(e) =>
                      handleDistributionChange(category, "numero_tapis", e.target.value)
                    }
                    className="border p-2 mt-1 w-full"
                  />
                </div>

                <button
                  onClick={() => handleSubmitDistribution(category, competiteurs)}
                  className="bg-blue-500 text-white p-2 mt-6 rounded-lg"
                >
                  Soumettre la distribution
                </button>
              </div>
            </div>
          ))
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default RepartitionCompetiteursPage;
