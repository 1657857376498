const groupeVingtTrois = (competiteurs) => {
  const groupeVingtTrois = {
    // Premier tour - 4 tableaux de 8 places chacun, avec des "byes"
    tour1: {
      tableauA: [
        null, // Bye (avantage à un compétiteur qui passe directement)
        null, // Bye
        competiteurs[1], // Combattant 2
        competiteurs[2], // Combattant 3
        null, // Bye
        null, // Bye
        competiteurs[4], // Combattant 5
        competiteurs[5], // Combattant 6
      ],
      tableauB: [
        null, // Bye
        null, // Bye
        null, // Bye
        null, // Bye
        null, // Bye
        null, // Bye
        competiteurs[9], // Combattant 10
        competiteurs[10], // Combattant 11
      ],
      tableauC: [
        null, // Bye
        null, // Bye
        competiteurs[12], // Combattant 13
        competiteurs[13], // Combattant 14
        null, // Bye
        null, // Bye
        competiteurs[15], // Combattant 16
        competiteurs[16], // Combattant 17
      ],
      tableauD: [
        null, // Bye
        null, // Bye
        competiteurs[18], // Combattant 19
        competiteurs[19], // Combattant 20
        null, // Bye
        null, // Bye
        competiteurs[21], // Combattant 22
        competiteurs[22], // Combattant 23
      ],
    },

    // Quarts de finale (les gagnants des combats avancent)
    quart_finale: {
      tableau: [
        competiteurs[0], // Saute un tour dans tableau A
        "gagnant_tableauA_2vs3", // Gagnant entre Combattant 2 vs Combattant 3
        competiteurs[3], // Saute un tour dans tableau A
        "gagnant_tableauA_5vs6", // Gagnant entre Combattant 5 vs Combattant 6
        competiteurs[6], // Saute un tour dans tableau B
        competiteurs[7], // Saute un tour dans tableau B
        competiteurs[8], // Saute un tour dans tableau B
        "gagnant_tableauB_10vs11", // Gagnant entre Combattant 10 vs Combattant 11
        competiteurs[11], // Saute un tour dans tableau C
        "gagnant_tableauC_13vs14", // Gagnant entre Combattant 13 vs Combattant 14
        competiteurs[14], // Saute un tour dans tableau C
        "gagnant_tableauC_16vs17", // Gagnant entre Combattant 16 vs Combattant 17
        competiteurs[17], // Saute un tour dans tableau D
        "gagnant_tableauD_19vs20", // Gagnant entre Combattant 19 vs Combattant 20
        competiteurs[20], // Saute un tour dans tableau D
        "gagnant_tableauD_22vs23", // Gagnant entre Combattant 22 vs Combattant 23
      ],
    },

    // Demi-finales
    demi_finale: {
      tableau: [
        "gagnant_quart_finale_1", // Gagnant Quart 1 Tableau A
        "gagnant_quart_finale_2", // Gagnant Quart 2 Tableau A
        "gagnant_quart_finale_3", // Gagnant Quart 3 Tableau B
        "gagnant_quart_finale_4", // Gagnant Quart 4 Tableau B
        "gagnant_quart_finale_5", // Gagnant Quart 5 Tableau C
        "gagnant_quart_finale_6", // Gagnant Quart 6 Tableau C
        "gagnant_quart_finale_7", // Gagnant Quart 7 Tableau D
        "gagnant_quart_finale_8", // Gagnant Quart 8 Tableau D
      ],
    },

    // Finale
    finale: {
      tableauFinale: [
        "gagnant_demi_finale_1", // Gagnant Demi-finale 1
        "gagnant_demi_finale_2", // Gagnant Demi-finale 2
      ],
    },

    // Double repêchage (pour les perdants des quarts et demi-finales)
    repechage: {
      tableauA: [
        "perdant_quart_finale_1", // Perdant du Quart 1
        "perdant_quart_finale_2", // Perdant du Quart 2
        "perdant_quart_finale_3", // Perdant du Quart 3
        "perdant_quart_finale_4", // Perdant du Quart 4
      ],
      tableauB: [
        "perdant_demi_finale_1", // Perdant Demi-finale 1
        "perdant_demi_finale_2", // Perdant Demi-finale 2
        "perdant_demi_finale_3", // Perdant Demi-finale 3
        "perdant_demi_finale_4", // Perdant Demi-finale 4
      ],
    },

    // Combats pour la 3ème place (les gagnants du repêchage)
    places_3eme: {
      combat: [
        "gagnant_repechage_A", // Vainqueur du repêchage tableau A
        "gagnant_repechage_B", // Vainqueur du repêchage tableau B
      ],
    },

    // Classement final
    classement: {
      1: "gagnant_finale", // Vainqueur de la finale
      2: "perdant_finale", // Perdant de la finale
      3: "gagnant_combat_3eme", // Vainqueur du combat pour la 3ème place
    },
  };

  return groupeVingtTrois;
};

export default groupeVingtTrois;
